import React from 'react';
import {Box, useMediaQuery} from '@mui/material';
import DocumentThumbnail from '../Document/DocumentThumbnail'; // Adjust the path as needed

const ImageGridContainer = ({
                                params,
                                docs,
                                handleItem,
                                downloadPdf,
                                downloadLoading,
                                handleDeleteOpen,
                                deleteOpen,
                                handleDeleteClose,
                                deleteDocument,
                                deleteLoading,
                                handleCheckboxChange,
                                selectedDocuments,
                                openDrawerChat
                            }) => {
    const xs = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const sm = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const md = useMediaQuery((theme) => theme.breakpoints.down('lg'));

    const columnCount = xs ? 1 : sm ? 3 : md ? 4 : openDrawerChat ? 5 : 6;

    return (
        <Box
            className="image-grid"
            sx={{
                display: 'grid',
                gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
                gap: 2,
                maxHeight: '90%',
                overflow: 'auto',
                // marginLeft: 1,
            }}
        >
            {(params.docIds.length > 0 ?
                    params.docIds
                        .map(id => docs.find(doc => doc.document_id.toString() === id))
                        .filter(doc => doc !== undefined) :
                    docs
            ).map((doc, index) => (
                <DocumentThumbnail
                    key={doc.document_id || index}
                    documentId={doc.document_id}
                    document={doc}
                    handleItem={handleItem}
                    downloadPdf={downloadPdf}
                    downloadLoading={downloadLoading}
                    handleDeleteOpen={handleDeleteOpen}
                    deleteOpen={deleteOpen}
                    handleDeleteClose={handleDeleteClose}
                    deleteDocument={deleteDocument}
                    deleteLoading={deleteLoading}
                    handleCheckboxChange={handleCheckboxChange}
                    selectedDocuments={selectedDocuments}
                />
            ))}
        </Box>
    );
};

export default ImageGridContainer;
