import React, {useState, useEffect} from 'react';
import {
    Box,
    Typography,
    TableFooter,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper, Select, MenuItem,
    Divider,
    InputAdornment,
    IconButton
} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import api from "../../API";
import Pagination from "../../components/Pagination/Pagination";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from '@mui/icons-material/Delete';
import GradientIcon from '../AR-Dashboard/grad-icon';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';
import DeleteConfirmationDialog from '../../components/Conformations/DeleteConfirmationDialog';
import {useTranslation} from 'react-i18next';


const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#ef798a', // Change this to your tab color
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#ef798a', // Change this to your tab indicator color
                },
            },
        },
    },
});

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};


const TagDatatable = () => {
    const [value, setValue] = useState(0);
    const [tagDetails, setTagDetails] = useState([]);
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [open, setOpen] = useState(false);
    const [newTagName, setNewTagName] = useState('');
    const [tagSearch, setTagSearch] = useState('');
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [deleteLoading, setDeleteLoading] = useState([false, null]);
    const [deleteTagId, setDeleteTagId] = useState(null);
    const {t} = useTranslation();

    const fetchTagDetails = async (url, params) => {
        try {
            const response = await api.get(url, {params});
            if (response.status !== 200) {
                console.error('Files loading error', response.status);
                return false;
            }
            const responseData = response.data;
            // console.log('TAGGS', responseData);
            setTagDetails(responseData.results);
            setTotalPages(responseData.count);
            return true;
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
        return false;
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setNewTagName('');
        setOpen(false);
    };

    const handleDeleteOpen = (tag_id) => {
        setDeleteOpen(true);
        setDeleteTagId(tag_id);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    const handleSave = async () => {
        try {
            const newRow = {tag_name: newTagName};
            const response = await api.post('data/tags', newRow);
            // Assign a new incremental ID
            const newId = tagDetails.length ? tagDetails[tagDetails.length - 1].tag_index + 1 : 1;
            const newRowWithId = {...response.data, tag_index: newId, index: newId};
            setTagDetails([...tagDetails, newRowWithId]);
            setNewTagName('');
            handleClose();
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && newTagName.trim()) {
            handleSave();
        }
    };

    const handleDelete = async (tag_id) => {
        setDeleteLoading([true, tag_id]);
        try {
            await api.delete(`/data/tags?tag_id=${tag_id}`);
            setDeleteOpen(false);
            const updatedRows = tagDetails.filter((row) => row.tag_id !== tag_id).map((row, index) => ({
                ...row,
                tag_index: index + 1,
                index: index + 1 // Update index property
            }));
            setTagDetails(updatedRows);
        } catch (error) {
            console.error('Error deleting data:', error);
        }
        setDeleteLoading([false, null])
    };

    const createParams = () => {
        const params = {};
        params.limit = limit;
        params.offset = (currentPage - 1) * limit;
        return params;
    };

    useEffect(() => {
        const params = createParams();
        fetchTagDetails('data/tags', params);
    }, [limit, currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handleLimitChange = (event) => {
        setLimit(Number(event.target.value));
        setCurrentPage(1);
    };

    const filteredTags = tagDetails.filter((tag) =>
        tag.tag_name.toLowerCase().includes(tagSearch.toLowerCase())
    );
    
    const TagDetails = ({tagDetails, limit, currentPage, totalPages, handlePageChange, handleLimitChange}) => {
        const [hoveredRow, setHoveredRow] = useState(null);

        const rowHeight = 50;
        const minRows = Math.ceil((50 * window.innerHeight) / 100 / rowHeight);

        return (
            <div>
                <TableContainer component={Paper} sx={{height: '70vh'}}>
                    <Table stickyHeader sx={{minWidth: 650, height: '70vh'}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{fontWeight: 'bold', width: '12%'}}>{t('index')}</TableCell>
                                <TableCell sx={{fontWeight: 'bold', width: '12%'}}>{t('name')}</TableCell>
                                <TableCell sx={{fontWeight: 'bold', width: '12%'}}>{t('action')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tagDetails.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{height: rowHeight, '&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {(currentPage - 1) * limit + index + 1}
                                    </TableCell>
                                    {/* <TableCell>{row.ip_address}</TableCell> */}
                                    <TableCell
                                        style={{cursor: 'pointer', color: hoveredRow === index ? '#ef798a' : 'inherit'}}
                                    >
                                        {row.tag_name}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="outlined"
                                            color='secondary'
                                            onClick={() => handleDeleteOpen(row.tag_id)}
                                            startIcon={<GradientIcon IconComponent={DeleteIcon}/>}
                                        >
                                            {t('delete')}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {Array.from({length: Math.max(minRows - tagDetails.length, 0)}).map((_, fillerIndex) => (
                                <TableRow key={`empty-${fillerIndex}`} sx={{height: rowHeight}}>
                                    <TableCell colSpan={3}/>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter sx={{
                            position: 'sticky',
                            bottom: 0,
                            backgroundColor: (theme) => theme.palette.background.paper,
                            zIndex: 1000,
                        }}>
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Box sx={{display: 'flex', alignItems: 'center', marginBottom: 2, mt: 2}}>
                                        <Pagination currentPage={currentPage} totalPages={totalPages}
                                                    onPageChange={handlePageChange}
                                                    handleLimitChange={handleLimitChange}
                                                    limit={limit}
                                                    perPageCount={[10, 15, 20]}/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        );
    };

    return (
        <Box sx={{paddingInline:2}}>
            <DeleteConfirmationDialog
                open={deleteOpen}
                onClose={handleDeleteClose}
                onDelete={handleDelete}
                deleteLoading={deleteLoading[0]}
                delDocumentId={deleteTagId}
                deletemessage={"Are you sure you want to delete this tag?"}
            />
            <div>
                <h2 style={{color: '#674f87', fontFamily: 'sans-serif',fontSize:30, marginBlock:15, marginLeft:25}}>
                    {t('tags')}
                </h2>
            </div>
            <Divider/>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingInline: 3, marginTop: '30px' }}>
                <TextField
                    label={t('search_tags')}
                    variant="outlined"
                    size="small"
                    color='secondary'
                    value={tagSearch}
                    onChange={(e) => setTagSearch(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton size="small">
                                    <GradientIcon IconComponent={SearchIcon}/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{marginRight: '16px',
                        width: 200,
                        // flex: 1,
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '4px',
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#ef798a',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#ef798a',
                            },
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ccc',
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: '10px',
                        },
                    }}
                />
                <Button
                    variant="contained"
                    onClick={handleClickOpen}
                    startIcon={<AddCircleOutlineIcon />}
                    size="small"
                    sx={{ background: 'linear-gradient(45deg, #674f87 30%, #b74770 90%)' }}
                >
                    {t('create')}
                </Button>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t('create_new_tag')}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="tag_name"
                        label={t('name')}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={newTagName}
                        onChange={(e) => setNewTagName(e.target.value)}
                        onKeyPress={handleKeyPress}
                        sx={{
                            '& .MuiInput-underline:hover:before': {
                                borderBottomColor: '#9E3C5D',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: '#9E3C5D',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                                color: '#9E3C5D',
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleClose}
                        sx={{ color: '#9E3C5D', backgroundColor: '#f1f1f1', border: '1px solid #9E3C5D' }}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleSave}
                        disabled={!newTagName.trim()}
                        sx={{ color: '#9E3C5D', backgroundColor: '#f1f1f1', border: '1px solid #9E3C5D' }}
                    >
                        {t('save')}
                    </Button>
                </DialogActions>
            </Dialog>
            <TabPanel value={value} index={0}>
                <TagDetails
                    tagDetails={filteredTags}
                    limit={limit}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    handleLimitChange={handleLimitChange}
                />
            </TabPanel>
        </Box>
    );
};

export default TagDatatable;
