import React, {useEffect} from 'react';
import {Box, Drawer, Stack, Tooltip, Typography} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import GradButton from "../Grad_Buttons/grad-icon-buttton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import GradientIcon from '../../pages/AR-Dashboard/grad-icon';
import {useTranslation} from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

const ChatDrawer = ({
                        open,
                        messages,
                        message,
                        setMessage,
                        handleKeyPress,
                        handleSearch,
                        chatContentRef,
                        handleDrawerChat,
                        aiLoading,
                        handleDocumentClick
                    }) => {

    const {t} = useTranslation();

    useEffect(() => {
        const chatContent = chatContentRef.current;
        const handleLinkClick = (event) => {
            if (event.target.classList.contains('doc-link')) {
                handleDocumentClick(event);
            }
        };
        chatContent.addEventListener('click', handleLinkClick);
        return () => {
            chatContent.removeEventListener('click', handleLinkClick);
        };
    }, [chatContentRef, handleDocumentClick]);


    return (
        <Drawer
            variant="persistent"
            anchor="right"
            open={open}
            sx={{
                '& .MuiDrawer-paper': {
                    width: '35vw',
                    transition: 'width 0.3s ease',
                },
            }}
        >
            <Typography sx={{
                fontWeight: 600,
                fontSize: '2rem',
                lineHeight: 1.2,
                marginTop: '70px',
                textAlign: 'center',
                letterSpacing: '0.01em',
                background: 'linear-gradient(to right, #ef798a, #674f87)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
            }}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <Box flexGrow={1} alignItems="center" justifyContent="center" display={'flex'}>
                        <GradientIcon IconComponent={AutoAwesomeIcon}/>
                        <Typography sx={{
                            fontWeight: 600,
                            fontSize: '1.5rem',
                            lineHeight: 1.2,
                            letterSpacing: '0.01em',
                            background: 'linear-gradient(to right, #ef798a, #674f87)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            marginLeft: 1
                        }}>
                            {t('ai_assistant')}
                        </Typography>
                    </Box>
                    <Tooltip title={t('close')}>
                        <span>
                            <GradButton
                                handleClick={handleDrawerChat}
                                sx={{border: 'none', padding: '0'}}
                                header_icon={HighlightOffIcon}
                            />
                        </span>
                    </Tooltip>

                </Stack>
            </Typography>
            <div
                className="chat-window"
                style={{
                    border: '2px solid #fff',
                    height: '88%',
                    width: '99%',
                    margin: 'auto',
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <div className='chat-content' ref={chatContentRef}
                     style={{
                         flex: 1,
                         padding: '10px',
                         overflowY: 'auto',
                         fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                         fontSize: '16px',
                         lineHeight: '1.8',
                         color: '#333',
                         letterSpacing: '0.5px'
                     }}>
                    {messages.map((msg, index) => (
                        <div
                            key={index}
                            style={{
                                textAlign: msg.from === 'user' ? 'right' : 'left',
                                margin: '10px 0',
                            }}
                        >
                            <span
                                style={{
                                    display: 'inline-block',
                                    padding: '12px 18px',
                                    borderRadius: '10px',
                                    backgroundColor: msg.from === 'user' ? '#525659' : '#d8d8d8',
                                    color: msg.from === 'user' ? '#fff' : '#000',
                                    maxWidth: '90%',
                                    wordWrap: 'break-word',
                                    fontWeight: '400',
                                    whiteSpace: 'pre-line',
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
                                }}
                            >
                                {msg.text.includes('*') ? (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: msg.text
                                                .replace(/\n/g, '<br/>')
                                                .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                                                .replace(/\*(.*?)\*/g, '• $1')
                                        }}
                                    />
                                ) : msg.text}
                            </span>
                        </div>
                    ))}
                </div>
                <div className='chat-input-container' style={{position: 'relative'}}>
                    <div className='chat-input'
                         style={{display: 'flex', borderTop: '1px solid #ccc', padding: '4px'}}>
                        <input
                            type='text'
                            value={message ?? ''}
                            disabled={aiLoading}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyPress={handleKeyPress}
                            style={{
                                flex: 1,
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #ccc'
                            }}
                            placeholder={t('chat_with_the_document_now')}
                        />
                        {aiLoading ? (<>
                                <svg width={0} height={0}>
                                    <defs>
                                        <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                            <stop offset="0%" stopColor="#e01cd5"/>
                                            <stop offset="100%" stopColor="#1CB5E0"/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <CircularProgress
                                    sx={{'svg circle': {stroke: 'url(#my_gradient)',}, alignItems: "center"}}/>
                            </>
                        ) : (
                            <button
                                disabled={aiLoading}
                                onClick={handleSearch}
                                style={{
                                    marginLeft: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #674F87',
                                    color: '#9D3C5D',
                                    cursor: 'pointer',
                                    alignItems: "center",
                                    backgroundColor: 'transparent'
                                }}
                            >
                                <SearchIcon
                                    style={{width: "22px", height: "22px", color: '#674F87'}}/>
                            </button>)
                        }
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default ChatDrawer;
