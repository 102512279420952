import React from 'react';
import { Button } from '@mui/material';
import {useTranslation} from 'react-i18next';

const OpenModalButton = ({ handleOpen }) => {

    const {t} = useTranslation();

    return (
        <Button
            variant="contained"
            onClick={handleOpen}
            sx={{
                background: 'linear-gradient(45deg, #674f87 30%, #b74770 90%)',
                color: 'white',
                '&:hover': {
                    background: 'linear-gradient(45deg, #674f87 30%, #b74770 90%)',
                },
            }}
        >
            {t('import')}
        </Button>
    );
};

export default OpenModalButton;
