import HomeIcon from '@mui/icons-material/Home';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import {useTranslation} from 'react-i18next';
import AccountBoxIcon from '@mui/icons-material/AccountBox';


const generateMainNavbarItems = (t) => [
    {
        id: 1,
        icon: <HomeIcon />,
        label: t('home'),  // 't' is passed as an argument from the component
        route: '',
    },
    {
        id: 2,
        icon: <DescriptionIcon/>,
        label: t('documents'),
        route: 'documents',
    },
    {
        id: 3,
        icon: <LocalOfferIcon />,
        label: t('tags'),
        route: 'tags',
    },
    {
        id: 4,
        icon: <AddToDriveIcon />,
        label: t('import'),
        route: 'drive',
    },
    {
        id: 5,
        icon: <ListAltIcon/>,
        label: t('activity_logs'),
        route: 'logs',
    },
    {
        id: 6,
        icon: <AccountBoxIcon/>,
        label: 'User Profile',
        route: 'user_profile',
    },
];

export const MainNavbarItemsComponent = () => {
    const {t} = useTranslation();

    const mainNavbarItems = generateMainNavbarItems(t);

    return mainNavbarItems;
};

export const mainProfileItems = [
    // Uncomment if needed
 /*   {
        id: 13,
        icon: <HelpIcon />,
        label: 'Help',
        route: 'help',
    },
    {
        id: 14,
        icon: <AccountBoxIcon />,
        label: 'Account Info',
        route: 'account-info',
    },*/
];