import * as React from 'react';
import {AppBar, Toolbar, Typography, Button, Tooltip, CircularProgress, useMediaQuery} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {topmenuStyles} from './styles';
import Ai_logo from '../images/meta_docs_grey.svg';
import {useAuth} from "../../contexts/AuthContext";
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import Popover from '@mui/material/Popover';
import {useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import LanguageChanger from "../Language/LanguageChanger";
import {useTranslation} from 'react-i18next';

export default function TopMenu({toggleDrawer, toggleCollapse}) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loading, setLoading] = useState(false); // Added loading state
    const isSmallerScreen = useMediaQuery('(max-width:1000px), (max-height:600px)');
    const {t} = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const {user, role, logout} = useAuth();
    const navigate = useNavigate();
    const handleLogout = async (e) => {
        setLoading(true);
        await logout();
        setLoading(false);
        navigate('/login');
    };
    return (
        <AppBar position="fixed" sx={topmenuStyles.top_menu}>
            <Toolbar>
                <div>
                    <Avatar onClick={isSmallerScreen ? toggleDrawer : toggleCollapse}
                            sx={{
                                border: '2px solid #f1f1f1',
                                cursor: 'pointer',
                                bgcolor: '#432F60',
                                color: '#f1f1f1',
                            }}
                    >
                        <MenuIcon/>
                    </Avatar>
                </div>
                <div onClick={() => navigate('/')}
                     style={{
                         cursor: 'pointer',
                         //  flexGrow: 1,
                         display: 'flex',
                         justifyContent: 'center',
                         marginLeft: 15
                     }}
                >
                    <img src={Ai_logo}
                         alt="MetaDocs"
                         style={{
                             width: '150px',
                             height: 'auto',
                         }}
                    />
                </div>
                <div style={{'flexGrow': 1}}></div>
                <LanguageChanger defaultColor={"#f1f1f1"}/>
                <div>
                    <Tooltip title={user? (user.name ? user.name: user.preferred_username): 'Guest'}>
                        <Avatar sx={{
                            border: '2px solid #f1f1f1',
                            cursor: 'pointer',
                            bgcolor: '#9D3C5D',
                            color: '#f1f1f1',
                            '&:hover': {bgcolor: '#432F60'},
                        }} onClick={handleClick}>
                            <PersonIcon/>
                        </Avatar>
                    </Tooltip>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div>
                            {user ? (
                                <div style={topmenuStyles.userInfo}>
                                    <Typography variant="body1" sx={{color: '#757575'}}>
                                        {user.name ? user.name: (user.preferred_username ?? 'Guest')}
                                    </Typography>
                                    <Typography variant="body2" sx={{color: '#757575'}}>
                                        {t('role')}: {role}
                                    </Typography>
                                </div>
                            ) : (
                                <Typography variant="body1" sx={{marginRight: '16px', padding: '16px'}}>
                                    {t('guest')}
                                </Typography>
                            )}
                        </div>
                        <Button variant='contained' color='secondary' size='small' onClick={handleLogout}
                                sx={{
                                    marginTop: '8px',
                                    width: '100%',
                                    borderTop: '1px solid #e0e0e0',
                                    paddingTop: '8px',
                                    overflow: 'hidden',
                                    position: 'relative'
                                }} disabled={loading}
                                endIcon={loading ? (<CircularProgress
                                    size={24}
                                    color="secondary"
                                    sx={{
                                        position: 'absolute',
                                        top: '15%',
                                        left: '40%',
                                        transform: 'translate(-50%, -50%)'
                                    }}
                                />) : null}
                        >
                            {loading ? '...' : t('logout')}
                        </Button>
                    </Popover>
                </div>
            </Toolbar>
        </AppBar>
    );
}
