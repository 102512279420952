export const FilterStrings = [
    "We have found several important documents for your question and narrowed down the selection to bring you the most relevant details.",
    "We located several key documents linked to your query and filtered the results to highlight the most relevant information.",
    "We identified several pertinent documents related to your inquiry and have streamlined the results to present only the most relevant information.",
    "Several significant documents have been identified in response to your inquiry, and we have filtered them to give you the most relevant data.",
    "We’ve discovered numerous relevant documents related to your request and filtered the results to provide the most crucial information.",
    "We found various important documents connected to your inquiry and have refined the list to show the most relevant ones.",
    "We have discovered several documents that pertain to your inquiry and have filtered them to present the most useful information.",
    "We've identified several documents relevant to your inquiry and have refined the list to include the most pertinent data.",
    "We have located numerous documents relevant to your query and narrowed them down to show only the most essential details.",
    "We’ve identified several pertinent documents associated with your inquiry and have filtered the results to offer the most useful information.",
    "Several key documents have been identified regarding your inquiry, and we’ve selected only the most relevant information for you.",
    "We've found several documents related to your query and filtered the results to deliver the most essential information.",
    "We have located several key documents in response to your inquiry and filtered the list to highlight the most relevant details.",
    "We’ve identified numerous relevant documents concerning your inquiry and have provided the most important information.",
    "We located several important documents regarding your query and narrowed down the selection to bring you the most useful information.",
    "Several documents have been identified as relevant to your inquiry, and we’ve filtered them to deliver the most important details.",
    "We identified multiple documents connected to your query and have narrowed them down to show only the most relevant ones.",
    "Several pertinent documents have been found in relation to your inquiry, and we have filtered the results to give you the most essential details.",
    "We have found several relevant documents linked to your inquiry and have refined the selection to include only the most important information.",
    "We’ve located several documents relevant to your query and filtered the results to bring you the most useful details.",
    "We identified numerous key documents regarding your inquiry and have narrowed down the list to show the most essential information.",
    "Several relevant documents have been identified for your inquiry, and we’ve filtered the results to offer the most important details.",
    "We’ve found numerous pertinent documents related to your question and have refined the results to include only the most useful information.",
    "We identified multiple relevant documents related to your query and have filtered them to give you the most essential data.",
    "We have discovered several important documents related to your query and filtered the results to highlight the most relevant details.",
    "We located several pertinent documents concerning your inquiry and refined the selection to show only the most critical information.",
    "We’ve found numerous documents that are pertinent to your question and filtered the results to provide the most important details.",
    "Several important documents have been identified in connection with your query, and we’ve narrowed down the results to the most relevant ones.",
    "We’ve located multiple documents relevant to your query and filtered them to bring you the most essential details.",
    "We identified several documents that are relevant to your inquiry and have filtered the results to present the most useful information.",
    "Several key documents related to your query have been found, and we have filtered them to provide you with the most relevant data.",
    "We’ve discovered several relevant documents regarding your inquiry and have narrowed the list to show the most crucial information.",
    "We identified numerous pertinent documents for your query and filtered them to offer the most relevant details.",
    "Several relevant documents have been located in response to your question, and we’ve filtered the results to give you the most useful data.",
    "We’ve found several key documents related to your query and refined the selection to highlight the most important information.",
    "Several pertinent documents have been found in connection with your inquiry, and we’ve narrowed the results to the most crucial information.",
    "We identified multiple key documents for your query and filtered the results to provide the most relevant details.",
    "We’ve located numerous documents related to your inquiry and filtered them to deliver only the most useful information.",
    "Several important documents have been found concerning your query, and we have filtered the results to give you the most essential information.",
    "We identified several documents relevant to your inquiry and refined the list to include only the most pertinent data.",
    "We have found numerous key documents related to your inquiry and filtered them to provide the most essential details.",
    "We’ve located several important documents for your query and filtered them to deliver the most relevant information.",
    "Several pertinent documents have been found in response to your question, and we’ve refined the results to show only the most important details.",
    "We’ve identified numerous key documents linked to your inquiry and filtered the results to bring you the most relevant information.",
    "We located several documents that pertain to your query and refined the selection to highlight the most useful details.",
    "We’ve identified several relevant documents related to your inquiry and have filtered the results to bring you the most crucial information.",
    "Numerous relevant documents have been located for your inquiry, and we’ve filtered the list to present the most essential details.",
    "We’ve discovered several pertinent documents connected to your query and filtered the results to give you the most important data.",
    "We identified numerous documents that are relevant to your query and have filtered them to offer only the most important information.",
    "Several key documents have been found in relation to your inquiry, and we’ve filtered the results to provide you with the most relevant details.",
    "We’ve found several pertinent documents for your question and refined the list to show the most useful information.",
    "We located multiple key documents concerning your inquiry and filtered them to provide the most relevant data.",
    "We’ve discovered numerous relevant documents related to your query and have refined the results to present the most essential details.",
    "We identified several important documents in connection with your inquiry and filtered the list to include only the most relevant information.",
    "We located numerous documents relevant to your query and filtered the selection to highlight the most important details.",
    "Several relevant documents have been found for your inquiry, and we’ve filtered the list to show the most essential information.",
    "We’ve identified numerous pertinent documents concerning your question and have refined the results to offer the most useful data.",
    "We located several key documents related to your inquiry and filtered the results to provide you with the most relevant information.",
    "Several important documents have been discovered for your query, and we’ve filtered them to deliver the most relevant details.",
    "We’ve identified several documents related to your inquiry and refined the results to show only the most important details.",
    "Numerous relevant documents have been found concerning your question, and we’ve filtered the results to offer the most essential information.",
    "We located several pertinent documents for your query and filtered the results to give you the most important details.",
    "We’ve identified multiple key documents relevant to your question and filtered the list to highlight the most useful information.",
    "We located several documents relevant to your inquiry and filtered them to present the most crucial details.",
    "Several important documents have been found for your inquiry, and we’ve refined the list to show only the most relevant data.",
    "We’ve identified several key documents related to your query and filtered them to give you the most important information.",
    "We located several documents pertinent to your query and filtered them to provide you with the most relevant details.",
    "We’ve identified numerous relevant documents for your inquiry and refined the results to highlight the most essential information.",
    "We’ve found several important documents for your question and filtered the list to provide the most useful details.",
    "Several pertinent documents have been found for your inquiry, and we’ve refined the results to give you the most relevant data.",
    "We’ve discovered several key documents for your query and filtered them to present only the most important information.",
    "We located multiple documents related to your inquiry and filtered them to give you the most useful details.",
    "We’ve found several relevant documents related to your query and filtered the results to provide you with the most important details.",
    "Several key documents have been identified for your question, and we’ve filtered the list to bring you the most essential information.",
    "We’ve identified multiple relevant documents for your inquiry and filtered the results to deliver the most crucial details.",
    "We’ve discovered several pertinent documents for your query and refined the results to provide the most useful information.",
    "We located several key documents connected to your question and filtered them to present the most important data.",
    "Numerous documents relevant to your inquiry have been identified, and we’ve filtered them to show the most essential information.",
    "We’ve found several pertinent documents linked to your inquiry and filtered the results to highlight the most useful details.",
    "We identified numerous important documents regarding your query and filtered the list to show the most relevant information.",
    "We’ve discovered several relevant documents for your question and filtered them to offer the most important details.",
    "Several key documents have been located in relation to your query, and we’ve refined the list to highlight the most essential information.",
    "We identified numerous documents related to your inquiry and filtered the results to give you the most important details.",
    "We’ve found several important documents regarding your inquiry and refined the results to present only the most useful information.",
    "Several key documents have been found regarding your question, and we’ve filtered them to deliver the most relevant details.",
    "We’ve identified several documents for your query and refined the results to provide the most essential information."]
export const UnknownStrings = [
    "Unfortunately, we couldn't locate the information you're seeking.",
    "Sorry, no relevant data could be found for your request.",
    "We were unable to find anything matching your query.",
    "No results were found for your request at this time.",
    "Apologies, we couldn't identify any relevant details for your search.",
    "Sadly, nothing matches your search criteria.",
    "It seems like we couldn’t find the information you're after.",
    "We’re sorry, but we couldn’t retrieve the requested data.",
    "No relevant documents could be found for your query.",
    "It appears that we don't have the data you're looking for.",
    "Unfortunately, there is no data available matching your search.",
    "Sorry, we couldn’t track down any relevant resources.",
    "We couldn't find the information you're requesting at the moment.",
    "Unfortunately, the requested information isn't available.",
    "No information could be retrieved for your query.",
    "Sorry, nothing relevant seems to be available right now.",
    "Unfortunately, we came up empty in our search for matching data.",
    "We apologize, but no data could be found at this time.",
    "It looks like the details you’re searching for aren’t available.",
    "We couldn’t find anything that fits your search query.",
    "No relevant matches were found for your request.",
    "Unfortunately, there were no results that match your input.",
    "Apologies, but we couldn’t locate the data you’re looking for.",
    "It seems there’s no matching information for your request.",
    "We couldn't find any details related to your search.",
    "There appears to be no matching information for your query.",
    "Unfortunately, nothing could be found based on your input.",
    "No results match the criteria you provided.",
    "Sadly, we were unable to retrieve relevant data.",
    "Our search didn’t return any results for your query.",
    "No matching results were found for your inquiry.",
    "Unfortunately, the search came up empty.",
    "There was no information available for your request.",
    "No data is currently available that matches your query.",
    "Sorry, but we couldn’t find any matching details.",
    "There’s no relevant data that we can find for your question.",
    "We regret that no matching information was discovered.",
    "Our search didn’t yield any relevant documents.",
    "It seems we couldn’t locate the results you’re after.",
    "No relevant content could be found based on your request.",
    "Sorry, we couldn't find anything that fits the details you provided.",
    "Unfortunately, we weren’t able to retrieve any matching results.",
    "We were unable to pull any results for the query you entered.",
    "Our system couldn’t identify any matching records for your request.",
    "It appears no results match what you’re looking for.",
    "Sorry, there’s no relevant data based on your search.",
    "We couldn’t find any results related to your inquiry.",
    "It seems we couldn’t pull any relevant information for your query.",
    "Our database didn’t return any results for the search criteria.",
    "No relevant records could be located for your question.",
    "Unfortunately, no relevant data was found at this time.",
    "It looks like we were unable to locate any matches for your request.",
    "No documents matching your criteria could be found.",
    "Sadly, we couldn’t find any results for your search.",
    "Sorry, there is no available data matching your query.",
    "No matching documents were found based on your input.",
    "It seems there’s no information that fits your search request.",
    "Unfortunately, we were unable to discover any relevant information.",
    "Our system couldn’t locate any matching details.",
    "We’re sorry, but we couldn’t find anything that fits your inquiry.",
    "Sadly, no records were found for your query.",
    "We couldn’t find anything corresponding to your search criteria.",
    "It appears that we don’t have the information you’re looking for.",
    "Unfortunately, our search didn’t yield any relevant data.",
    "Sorry, we weren’t able to retrieve any matches for your input.",
    "No relevant results could be pulled from our system.",
    "We couldn’t locate any relevant documents for your query.",
    "It seems there’s no data available that matches your search.",
    "Apologies, but we couldn’t find any records that fit your criteria.",
    "There doesn’t appear to be any data that matches your request.",
    "Sadly, nothing was found for your search query.",
    "Unfortunately, no matching results could be retrieved.",
    "We couldn’t pull any data that corresponds to your inquiry.",
    "It looks like there are no relevant results available for your search.",
    "No records matching your search terms could be found.",
    "We were unable to retrieve any information relevant to your query.",
    "Unfortunately, no relevant documents could be identified.",
    "Sorry, there were no matches for the query you provided.",
    "No matching records could be found based on your criteria.",
    "We couldn’t retrieve any relevant data based on your input.",
    "Our system didn’t find any documents that match your query.",
    "No relevant results were found from our search.",
    "It seems we couldn’t pull any relevant records at this time.",
    "There doesn’t seem to be any data matching your query right now.",
    "Sadly, there were no relevant results found for your inquiry.",
    "No relevant information could be located for your request.",
    "Our system didn’t yield any results that match your search terms.",
    "We couldn’t locate any relevant documents based on your criteria.",
    "It appears we couldn’t retrieve any matches for your request.",
    "No relevant documents were retrieved for your inquiry.",
    "Unfortunately, no matching results could be found for your query.",
    "We apologize, but no relevant information could be found.",
    "Sorry, no matching data could be found for your request."
]