import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

const CustomSnackbar = ({open, message, onClose}) => (
    <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={onClose}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}

    >
        <SnackbarContent
            sx={{
                backgroundColor: '#f1f1f1',
                color: '#000',
                fontSize: '15px',
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
            }}
            message={message}
        />
    </Snackbar>
);

export default CustomSnackbar;