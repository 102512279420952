import React, {useState, useEffect} from 'react';
import { Avatar, Box, Button, Card, CardContent, Divider, Grid, IconButton,
         Switch, Typography, FormControl, TextField, LinearProgress,
         Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { Facebook, Instagram, Twitter } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import VerifiedIcon from '@mui/icons-material/Verified';
import api from '../../API';
import GradButton from '../../components/Grad_Buttons/grad-icon-buttton';
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';

const ProfileSettings = () => {

    const [userData, setUserData] = useState({});
    const [formData, setFormData] = useState({
        currentPassword: '', newPassword: '', confirmPassword: ''})
    const uploadedData = userData.user_data_uploaded_in_mb || 0;
    const maxUploadLimit = userData.user_max_upload_limit || 0;
    const uploadPercentage = (uploadedData / maxUploadLimit) * 100;
    const spaceLeft = (maxUploadLimit-uploadedData)

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const openMenu = Boolean(anchorEl);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const params = {username: localStorage.getItem('username')};
                const response = await api.get('report/users', {params});
                if (response.status !== 200) {
                    console.error('Files loading error', response.status);
                    return false;
                }
                const responseData = await response.data;
                setUserData(responseData.results);
                return true;
            } catch (error) {
                console.error('Error fetching documents:', error);
            }
            return false;
        };
        fetchUser();
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handlePasswordChange = () => {
        setAnchorEl(null);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };


    return (
        <Box sx={{ flexGrow: 1, marginInline:5 }}>
            <Box
                sx={{
                    display:'flex',
                    height: 300,
                    // backgroundImage: 'url(https://via.placeholder.com/1500x500)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: 5,
                    background: `linear-gradient(to right, #9E3C5D, #432F60)`,
                    marginTop: 2,
                    paddingInline: 3,
                    paddingBlock: 2
                }}
            >
                <Avatar  sx={{ width: 200, height: 200, color: "#614e79", background: `linear-gradient(to right, #efd6df, #d8cee7)` }} >
                    <PersonIcon sx={{ width: 150, height: 150,}}/>
                </Avatar>
                <Box sx={{ marginLeft: 5, marginTop: 8}}>
                    <Typography variant="h3" sx={{
                        fontWeight: 'bold',
                        color: '#fff'
                    }}>{userData.user_first_name} {userData.user_last_name}</Typography>
                    <Typography variant="body1" color="#fff">{userData.user_role}</Typography>
                </Box>
            </Box>
            <Box container  sx={{ mt: -8, px: 3 }}>
                <Box sx={{display: 'block', gap: 3, borderRadius: 3, boxShadow: 3, background: `linear-gradient(to right, #efd6df, #d8cee7)`, padding: 2 }}>
                    <Box display={'flex'} padding={2}>
                        <Typography variant="h4" color={"#432F60"} sx={{ fontWeight: 'bold', padding: 2 }}>
                            Profile Information
                        </Typography>
                        <Box flexGrow={1}/>
                        <Box>
                            {/*<GradButton header={'Edit'} handleClick={handleClick} header_icon={EditIcon}/>*/}
                            <Menu
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleCloseMenu}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={handlePasswordChange}>Change Password</MenuItem>
                                <MenuItem onClick={handleCloseMenu}>Others</MenuItem>
                            </Menu>

                            {/* Password Change Dialog */}
                            <Dialog color='secondary' open={openDialog} onClose={handleCloseDialog}>
                                <DialogTitle>
                                    Change Password
                                    <IconButton
                                        aria-label="close"
                                        color='secondary'
                                        onClick={handleCloseDialog}
                                        sx={{ position: 'absolute', right: 8, top: 8 }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent sx={{maxWidth: 400}}>
                                    <DialogContentText>
                                        Please enter your current and new password:
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        label="Current Password"
                                        type="password"
                                        value={formData.currentPassword}
                                        onChange={handleChange}
                                        color='secondary'
                                        fullWidth
                                        variant="outlined"
                                    />
                                    <TextField
                                        margin="dense"
                                        label="New Password"
                                        type="password"
                                        value={formData.newPassword}
                                        onChange={handleChange}
                                        color='secondary'
                                        fullWidth
                                        variant="outlined"
                                    />
                                    <TextField
                                        margin="dense"
                                        label="Confirm Password"
                                        type="password"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        color='secondary'
                                        fullWidth
                                        variant="outlined"
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button color='secondary' onClick={handleCloseDialog}>Cancel</Button>
                                    <Button variant="contained"  color='secondary'>Submit</Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', gap: 3, padding: 2 ,}}>
                        <Box sx={{ display: 'block', borderRadius: 0, boxShadow: 0, padding:2, gap: 2, width: 400 }}>
                            <Typography variant="h5" color={"#432F60"} sx={{ fontWeight: 'bold', mb: 1, textAlign:"center" }}>
                                User Details
                            </Typography>
                            <Box display={'flex'} gap={2} sx={{borderRadius: 1, background: '#fff', padding: 1, mb: 1, boxShadow: 3  }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Username:</Typography>
                                <Typography variant="h6" >{userData.username}</Typography>
                            </Box>
                            <Box display={'flex'} gap={2} sx={{borderRadius: 1, background: '#fff', padding: 1, mb: 1, boxShadow: 3  }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>User Role:</Typography>
                                <Typography variant="h6" >{userData.user_role}</Typography>
                            </Box>
                            <Box display={'flex'} gap={2} sx={{borderRadius: 1, background: '#fff', padding: 1, mb: 1, boxShadow: 3  }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Realm:</Typography>
                                <Typography variant="h6" >{userData.realm}</Typography>
                            </Box>
                        </Box>
                        <Divider orientation='vertical' flexItem sx={{border: '2px solid #C4B7C8'}}/>
                        <Box sx={{ display: 'block', borderRadius: 0, boxShadow: 0, padding:2, gap: 2, width: 500  }}>
                            <Typography variant="h5" color={"#432F60"} sx={{ fontWeight: 'bold', mb: 1, textAlign:"center" }}>
                                Personal Information
                            </Typography>
                            <Box display={'flex'} gap={2} sx={{borderRadius: 1, background: '#fff', padding: 1, mb: 1, boxShadow: 3  }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>First Name:</Typography>
                                <Typography variant="h6" >{userData.user_first_name}</Typography>
                            </Box>
                            <Box display={'flex'} gap={2} sx={{borderRadius: 1, background: '#fff', padding: 1, mb: 1, boxShadow: 3  }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Last Name:</Typography>
                                <Typography variant="h6" >{userData.user_last_name}</Typography>
                            </Box>
                            <Box display={'flex'} gap={2} sx={{borderRadius: 1, background: '#fff', padding: 1, mb: 1, boxShadow: 3  }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>E-mail:</Typography>
                                <Typography variant="h6" >{userData.user_email}</Typography>
                                {<VerifiedIcon color='success'/>}
                            </Box>
                            <Box display={'flex'} gap={2} sx={{borderRadius: 1, background: '#fff', padding: 1, mb: 1, boxShadow: 3  }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Organization:</Typography>
                                <Typography variant="h6" >{userData.user_organization}</Typography>
                            </Box>
                            <Box display={'flex'} gap={2} sx={{borderRadius: 1, background: '#fff', padding: 1, mb: 1, boxShadow: 3  }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Contact:</Typography>
                                <Typography variant="h6" >{userData.user_contact}</Typography>
                            </Box>
                        </Box>
                        <Divider orientation='vertical' flexItem sx={{border: '2px solid #C4B7C8'}}/>
                        <Box sx={{ display: 'block', borderRadius: 0, boxShadow: 0, padding:2, gap: 2, width: 400  }}>
                            <Typography variant="h5" color={"#432F60"} sx={{ fontWeight: 'bold', mb: 1, textAlign: 'center' }}>
                                Storage
                            </Typography>
                            {maxUploadLimit !== 0 ? (
                                <Card sx={{ minWidth: 275, borderRadius: 3, boxShadow: 3 }}>
                                    <CardContent>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Box sx={{ width: '100%', mt: 1 }}>
                                                <Typography variant="body1" sx={{ textAlign: 'center', marginBlock: 1, fontWeight:'bold' }}>
                                                    {uploadPercentage.toFixed(2)}% full
                                                </Typography>

                                                {/* Linear Progress Bar */}
                                                <LinearProgress variant="determinate" color='secondary' value={uploadPercentage} sx={{ borderRadius: 5, height: 10 }} />

                                                <Typography variant="body2" sx={{ textAlign: 'center', mt: 1, fontWeight:'bold' }}>
                                                    {uploadedData} MB of {maxUploadLimit} MB used
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            ):(
                                <Card sx={{ minWidth: 275, borderRadius: 3, boxShadow: 3 }}>
                                    <CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <CloudUploadIcon color='secondary' sx={{ fontSize: 50, marginInline: 3}} />
                                            <Box sx={{ flexGrow: 1, justifyContent: 'center' }}>
                                                <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                                    Uploaded : <span style={{ fontSize: 20 }}>{uploadedData} MB</span>
                                                </Typography>
                                                <Typography variant="body2" sx={{ textAlign: 'left', mt: 1, fontWeight: 'bold' }}>
                                                    Storage Limit : <span style={{ fontSize: 20 }}>Unlimited</span>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                                )
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ProfileSettings;

