import React, {useState, useEffect} from "react";
import {
    Grid,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    CircularProgress
} from "@mui/material";
import api from "../../API";
import {useTranslation} from 'react-i18next';

const RecentActivitiesTable = () => {
    const [recentActivities, setRecentActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const {t} = useTranslation();


    // Fetch recent activities from API
    useEffect(() => {
        const fetchRecentActivities = async () => {
            try {
                const params = {activity_types: ["Document Download", "Document Deleted", "Document Edited", "Document Import"].join(',')};
                const response = await api.get("/data/log_data", {params});
                setRecentActivities(response.data.results);
            } catch (error) {
                setError("Failed to fetch recent activities.");
            } finally {
                setLoading(false);
            }
        };

        fetchRecentActivities();
    }, []);

    if (loading) {
        return (
            <Grid container justifyContent="center">
                <CircularProgress/>
            </Grid>
        );
    }

    if (error) {
        return (
            <Grid container justifyContent="center">
                <Typography color="error">{error}</Typography>
            </Grid>
        );
    }

    return (
        <Grid item xs={12}>
            <Paper sx={{padding: 2}}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Recent Activities</Typography>
                    <Link underline="none" sx={{fontWeight: 'bold', color: '#ef798a'}} href={'/logs'}>
                        {t('view_all')}
                    </Link>
                </Grid>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('activity')}</TableCell>
                                <TableCell>{t('by')}</TableCell>
                                <TableCell>{t('date')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {recentActivities.map((activity, index) => (
                                <TableRow key={index}>
                                    <TableCell>{activity.activity_type}</TableCell>
                                    <TableCell>{activity.username}</TableCell>
                                    <TableCell>{activity.created_at}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid>
    );
};

export default RecentActivitiesTable;
