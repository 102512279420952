import React, { createContext, useContext, useState } from 'react';

const AssistContext = createContext();

export const AssistProvider = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <AssistContext.Provider value={{ collapsed, setCollapsed }}>
      {children}
    </AssistContext.Provider>
  );
};

export const useAssist = () => useContext(AssistContext);