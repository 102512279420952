import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

const resources = {
    en: {
        language: "English",
        translation: {
            "welcome": "Welcome to",
            "username": "Username",
            "password": "Password",
            "confirm_password": "Confirm Password",
            "sign_in": "SIGN IN",
            "sign_up": "Sign Up",
            "logout": "Logout",
            "forgot_password": "Forgot Password ?",
            "dont_you_have_an_account": "Don't have an account?",
            "already_have_an_account": "Already have an account?",
            "create_account": "Create Account",
            "email": "Email",
            "first_name": "First Name",
            "last_name": "Last Name",
            "organization": "Organization",
            "phone": "Phone",
            "username_is_required": "Username is required",
            "password_is_required": "Password is required",
            "logging_in": "Logging In...",
            "username _should _be_of_minimum_3_characters": "Username should be of minimum 3 characters.",
            "please_enter_a_valid_email address.": "Please enter a valid email address.",
            "password_helper_text": "Password must be at least 8 characters long and should contain at least one number, one lowercase letter, one uppercase letter, and one special character.",
            "passwords_do_not_match": "Passwords do not match.",
            "user_creation_succesfull'": "User created. Please sign in with new user.",
            "user_creation_failed": "User creation failed",
            "submit": "Submit",
            "next": "Next",
            "back": "Back",
            "role": "Role",
            "guest": "Guest",
            "home": "Home",
            "loading_chart": "Loading chart...",
            "total_documents": "Total Documents",
            "documents": "Documents",
            "categories": "Categories",
            "users": "Users",
            "recent_files": "Recent Files",
            "view_all": "View All",
            "uploaded": "Uploaded",
            "type": "Type",
            "by": "By",
            "date": "Date",
            "are_you_sure_you_want_to_delete_this_file": "Are you sure you want to delete this file",
            "ai_assistant": "AI Assistant",
            "close": "Close",
            "chat_with_the_document_now": "Chat with the document now...",
            "per_page": "Per Page:",
            "page_no": "Page No :",
            "page_number_is_out_of_range": "Page number is out of range",
            "page": "Page",
            "document_downloaded_successfully": "Document Downloaded successfully",
            "document_deleted_successfully": "Document Deleted successfully",
            "document_saved_successfully": "Document Saved successfully",
            "tags": "Tags",
            "name/content_search": "Name / Content Search",
            "ai": "AI",
            "folder_view": "Folder View",
            "item_view": "Item View",
            "upload": "Upload",
            "search_tags": "Search Tags",
            "search_types": "Search Types",
            "select_a_file_to_upload": "Select a file to upload",
            "total_size_exceeds_limit": "The total size of selected files exceeds the ${maxSize} MB limit.",
            "files_uploaded_successfully": "Files Uploaded successfully",
            "drag_and_drop_or_click_to_select_files": "Drag and drop or click to select files",
            "reselect_files": "Reselect Files",
            "delete": "Delete",
            "summarizing": "Summarizing...",
            "tag_saving_unsuccessful": "Tag Saving unsuccessful",
            "tag_saving_successful": "Tag Saving successful",
            "failed_to_fetch_document": "Failed to fetch document",
            "create_new_tag": "Create New Tag",
            "name": "Name",
            "cancel": "Cancel",
            "save": "Save",
            "download": "Download",
            "edit": "Edit",
            "version": "Version",
            "are_you_sure_you_want_to_close_this_file": "Your recent changes have not been saved.Are you sure you want to close this file?",
            "no": "No",
            "yes": "Yes",
            "please_ensure_you_save_your_changes": "Your recent changes have not been saved. Please ensure you save your changes.",
            "document": "Document",
            "document_name": "Document Name",
            "file_name": "File Name",
            "document_type": "Document Type",
            "update_tags": "Update Tags",
            "summarize_now": "Summarize Now",
            "index": "Index",
            "action": "Action",
            "create": "Create",
            "log_data": "Log Data",
            "from_date": "From Date",
            "to_date": "To Date",
            "user": "User",
            "activity": "Activity",
            "details": "Details",
            "time": "Time",
            "import_documents": "Import Documents",
            "import": "Import",
            "classification_status": "Classification Status",
            "extraction_status": "Extraction Status",
            "completed": "Completed",
            "pending": "Pending",
            "started": "Started",
            "failed": "Failed",
            "queue": "Queue",
            "upload_status": "Upload Status",
            "upload_success": "upload_success",
            "upload_pending": "upload_pending",
            "upload_failed": "upload_failed",
            "created_at": "Created At",
            "loading_data": "Loading data...",
            "extraction_label": "Extraction Label",
            "new": "New",
            "unclassified": "Unclassified",
            "classification_type": "Classification Type",
            "activity_logs": "Activity Logs",
            "clear_chat": "Clear Chat",
            "select_language": "Select Language",
            
        }
    },
    de: {
        language: "German",
        translation: {
            "welcome": "Willkommen bei",
            "username": "Benutzername",
            "password": "Passwort",
            "confirm_password": "Passwort bestätigen",
            "sign_in": "Einloggen",
            "sign_up": "Registrieren",
            "logout": "Ausloggen",
            "forgot_password": "Passwort vergessen?",
            "dont_you_have_an_account": "Haben Sie noch kein Konto?",
            "already_have_an_account": "Haben Sie bereits ein Konto?",
            "create_account": "Konto erstellen",
            "email": "E-Mail",
            "first_name": "Vorname",
            "last_name": "Nachname",
            "organization": "Organisation",
            "phone": "Telefon",
            "username_is_required": "Benutzername ist erforderlich",
            "password_is_required": "Passwort ist erforderlich",
            "logging_in": "Anmeldung läuft...",
            "username _should _be_of_minimum_3_characters": "Der Benutzername muss mindestens 3 Zeichen lang sein.",
            "please_enter_a_valid_email address.": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
            "password_helper_text": "Das Passwort muss mindestens 8 Zeichen lang sein und mindestens eine Zahl, einen Kleinbuchstaben, einen Großbuchstaben und ein Sonderzeichen enthalten.",
            "passwords_do_not_match": "Die Passwörter stimmen nicht überein.",
            "user_creation_succesfull'": "Benutzer erstellt. Bitte melden Sie sich mit dem neuen Benutzer an.",
            "user_creation_failed": "Benutzererstellung fehlgeschlagen.",
            "submit": "Absenden",
            "next": "Weiter",
            "back": "Zurück",
            "role": "Rolle",
            "guest": "Gast",
            "home": "Startseite",
            "loading_chart": "Diagramm wird geladen...",
            "total_documents": "Gesamtdokumente",
            "documents": "Dokumente",
            "categories": "Kategorien",
            "users": "Benutzer",
            "recent_files": "Letzte Dateien",
            "view_all": "Alle ansehen",
            "uploaded": "Hochgeladen",
            "type": "Typ",
            "by": "Von",
            "date": "Datum",
            "are_you_sure_you_want_to_delete_this_file": "Möchten Sie diese Datei wirklich löschen?",
            "ai_assistant": "AI-Assistent",
            "close": "Schließen",
            "chat_with_the_document_now": "Jetzt mit dem Dokument chatten...",
            "per_page": "Pro Seite:",
            "page_no": "Seite Nr :",
            "page_number_is_out_of_range": "Seitennummer außerhalb des Bereichs",
            "page": "Seite",
            "document_downloaded_successfully": "Dokument erfolgreich heruntergeladen",
            "document_deleted_successfully": "Dokument erfolgreich gelöscht",
            "document_saved_successfully": "Dokument erfolgreich gespeichert",
            "tags": "Tags",
            "name/content_search": "Name/Inhalts-Suche",
            "ai": "AI",
            "folder_view": "Ordneransicht",
            "item_view": "Elementansicht",
            "upload": "Hochladen",
            "search_tags": "Tags durchsuchen",
            "search_types": "Typen durchsuchen",
            "select_a_file_to_upload": "Wählen Sie eine Datei zum Hochladen aus",
            "total_size_exceeds_limit": "Die Gesamtgröße der ausgewählten Dateien überschreitet das Limit von ${maxSize} MB.",
            "files_uploaded_successfully": "Dateien erfolgreich hochgeladen",
            "drag_and_drop_or_click_to_select_files": "Ziehen Sie die Dateien hierher oder klicken Sie, um sie auszuwählen",
            "reselect_files": "Dateien erneut auswählen",
            "delete": "Löschen",
            "summarizing": "Zusammenfassen...",
            "tag_saving_unsuccessful": "Tag-Speichern fehlgeschlagen",
            "tag_saving_successful": "Tag erfolgreich gespeichert",
            "failed_to_fetch_document": "Dokument konnte nicht abgerufen werden",
            "create_new_tag": "Neuen Tag erstellen",
            "name": "Name",
            "cancel": "Abbrechen",
            "save": "Speichern",
            "download": "Herunterladen",
            "edit": "Bearbeiten",
            "version": "Version",
            "are_you_sure_you_want_to_close_this_file": "Ihre letzten Änderungen wurden nicht gespeichert. Möchten Sie diese Datei wirklich schließen?",
            "no": "Nein",
            "yes": "Ja",
            "please_ensure_you_save_your_changes": "Ihre letzten Änderungen wurden nicht gespeichert. Bitte stellen Sie sicher, dass Sie Ihre Änderungen speichern.",
            "document": "Dokument",
            "document_name": "Dokumentname",
            "file_name": "Dateiname",
            "document_type": "Dokumenttyp",
            "update_tags": "Tags aktualisieren",
            "summarize_now": "Jetzt zusammenfassen",
            "index": "Index",
            "action": "Aktion",
            "create": "Erstellen",
            "log_data": "Protokolldaten",
            "from_date": "Von Datum",
            "to_date": "Bis Datum",
            "user": "Benutzer",
            "activity": "Aktivität",
            "details": "Details",
            "time": "Zeit",
            "import_documents": "Dokumente importieren",
            "import": "Importieren",
            "classification_status": "Klassifizierungsstatus",
            "extraction_status": "Extraktionsstatus",
            "completed": "Abgeschlossen",
            "pending": "Ausstehend",
            "started": "Gestartet",
            "failed": "Fehlgeschlagen",
            "queue": "Warteschlange",
            "upload_status": "Upload-Status",
            "upload_success": "Hochladen erfolgreich",
            "upload_pending": "Hochladen ausstehend",
            "upload_failed": "Hochladen fehlgeschlagen",
            "created_at": "Erstellt am",
            "loading_data": "Daten werden geladen...",
            "extraction_label": "Extraktionslabel",
            "new": "Neu",
            "unclassified": "Unklassifiziert",
            "classification_type": "Klassifizierungstyp",
            "activity_logs": "Aktivitätsprotokolle",
            "clear_chat": "Chat löschen",
            "select_language": "Sprache auswählen",

        }
    }
};
const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;
i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('language') || defaultLanguage,
    fallbackLng: localStorage.getItem('language') || defaultLanguage,
    interpolation: {escapeValue: false}
});

export default i18n;
