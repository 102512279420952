import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Grid, Box} from '@mui/material';
import api from '../../API';
import ApexCharts from 'react-apexcharts';
import RecentActivitiesTable from "../../components/Tables/RecentActivitiesTable";
import RecentFilesTable from "../../components/Tables/RecentFilesTable";
import InfoCard from '../../components/Datagrid/InfoCard';
import {useTranslation} from 'react-i18next';

const DataTable = () => {
    return (
        <Grid container spacing={3} sx={{ padding: 3 }}>
            <RecentFilesTable/>
            <RecentActivitiesTable/>
        </Grid>
    );
};

const DonutChart = ({series, labels, handleNavigate}) => {

    const {t} = useTranslation();

    const options = {
        chart: {
            type: 'donut',
            events: {
                click: (event, chartContext, config) => {
                    const dataPointIndex = config.dataPointIndex;
                    if (dataPointIndex !== undefined && labels[dataPointIndex] && series[dataPointIndex]) {
                        const selectedType = {
                            document_type: labels[dataPointIndex],
                            total_count: series[dataPointIndex]
                        };
                        handleNavigate(selectedType);
                    } else {
                        console.error('Invalid data point selected or chart data is not available');
                    }
                }
            }
        },
        labels: labels,
        // colors: ['#674f87', '#ef798a', '#8b6cfc', '#fbc760', '#66cc99'],
        colors: ['#442F60','#6D355F', '#9A3C5E', '#684893', '#ef798a'],
        dataLabels: {
            enabled: true,
            formatter: (val) => `${Math.round(val)}%`,
        },
        legend: {
            show: true,
            position: 'bottom',
        },
    };

    return (
        series && series.length > 0 && labels && labels.length > 0 ? ( // Add checks for series and labels
            <ApexCharts options={options} series={series} type="donut" width="380"/>
        ) : (
            <div>{t('loading_chart')}</div>
        )
    );
};

// Main OrderCard component
const OrderCard = () => {
    const [docTypes, setDocTypes] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true); // Add loading state
    const {t} = useTranslation();

    useEffect(() => {
        const fetchTypes = async () => {
            setIsLoading(true);
            try {   
                const response = await api.get('data/doc_type');
                if (response.status !== 200) {
                    console.error('Files loading error', response.status);
                    return false;
                }
                const responseData = await response.data;
                const totalSum = responseData.results.length > 0 ? responseData.results.reduce((sum, item) => sum + item.total_count, 0) : 0;
                const updatedDocTypes = [{
                    document_type: t('total_documents'),
                    total_count: totalSum
                }, ...responseData.results];
                setDocTypes(updatedDocTypes);
                setIsLoading(false);
                return true;
            } catch (error) {
                console.error('Error fetching documents:', error);
            }
            return false;
        };
        fetchTypes();
    }, []);

    const handleNavigate = (selectedType) => {
        if (selectedType.document_type !== t('total_documents')) {
            navigate('/documents', {state: {defaultDocType: selectedType}});
        } else {
            navigate('/documents', {state: {}});
        }
    };

    // Prepare data for the donut chart
    const filteredDocTypes = docTypes.filter(type => type.document_type !== t('total_documents'));
    const chartSeries = filteredDocTypes.map(type => type.total_count);
    const chartLabels = filteredDocTypes.map(type => type.document_type);

    return (
        <Box sx={{marginTop: '30px'}}>
            <Box sx={{marginTop: 2}}> 
                <InfoCard docTypes={docTypes}/>
            </Box>
            <Box sx={{marginTop: 4, display: 'flex'}}>
                <DataTable/>
                {isLoading ? ( // Conditional rendering
                    <div>{t('loading_chart')}</div>
                ) : (
                    <DonutChart series={chartSeries} labels={chartLabels} handleNavigate={handleNavigate}/>
                )}
            </Box>
        </Box>
    );
};

export default OrderCard;
