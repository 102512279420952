import { Padding } from "@mui/icons-material";

export const navbarStyles = {
    drawer: {
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            backgroundColor: '#191F2B',
            color: 'rgba(255, 255, 255, 0.7)',
        },
        '& .Mui-selected': {
            width: '100%',
            backgroundColor: '#432F60',
            background: `linear-gradient(to right, #432F60, #9E3C5D)`,
            color: '#FFFFFF',
            '& .MuiListItemIcon-root': {
                color: '#FFFFFF',
            },
            '& .MuiListItemText-primary': {
                color: '#FFFFFF',
            },
        },
    },
    icons: {
        marginLeft: '20px',
        color: '#FFFFFF',
    },
    list: {
        '&:hover': {
            width: '100%',
            backgroundColor: '#432F60',
            background: `linear-gradient(to right, #432F60, #9E3C5D)`,
            color: '#FFFFFF',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: '#FFFFFF',
            },
        },
        '& span': {
            color: '#FFFFFF'
        },
    },
    text: {
        
        '& span': {
            marginLeft: '-10px',
            marginRight: '50px',
        }
    }
};