import React from 'react';
import { Box, IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const WopiFrame = ({ handleEditClose, iframeSrc, accessToken, editDoc }) => {
    return (
        <Box sx={{ position: 'relative', height: '100vh', background: 'linear-gradient(to bottom, #ffffff, #eae8ed)' }}>
            {/* Close button */}
            <IconButton
                onClick={handleEditClose}
                sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    zIndex: 1000,
                    backgroundColor: '#ffffff',
                    borderRadius: '50%',
                    '&:hover': {
                        backgroundColor: '#eae8ed',
                    },
                }}
            >
                <HighlightOffIcon />
            </IconButton>
            <iframe
                srcDoc={`
                    <!DOCTYPE html>
                    <html lang="en">
                      <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Load Collabora Online</title>
                      </head>
                      <body>
                        <form
                          id="libreOfficeForm"
                          action="${iframeSrc}"
                          encType="multipart/form-data"
                          method="post">
                          <input name="access_token" value="${accessToken}">
                          <input type="submit"/>
                        </form>
                        <script>
                          ${editDoc ? "document.getElementById('libreOfficeForm').submit();" : ""}
                        </script>
                      </body>
                    </html>
                  `}
                style={{
                    width: '100%',
                    height: '95%',
                    border: 'none',
                }}
                title="WOPI Iframe"
            />
        </Box>
    );
};

export default WopiFrame;
