import React from 'react';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const CustomDatePicker = ({ label, value, onChange, minDate=null }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={label}
                value={value}
                onChange={onChange}
                minDate={minDate}
                maxDate={dayjs()} // Restricting to today and earlier
                sx={{
                    marginLeft: 1,
                    width: '170px',
                    '& .MuiInputBase-root': {
                        height: '40px',
                        fontSize: '14px',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ef798a',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline, &.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ef798a',
                        },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: value ? '#ef798a' : '#ccc',
                    },
                    '& .MuiInputLabel-root': {
                        fontSize: '16px',
                        transform: 'translate(14px, 10px) scale(1)',
                        '&.Mui-focused': {
                            color: '#ef798a'
                        }
                    },
                    '& .MuiInputLabel-shrink': {
                        transform: 'translate(11px, -6px) scale(0.75)',
                        color: '#ef798a'
                    },
                }}
            />

        </LocalizationProvider>
    );
};

export default CustomDatePicker;
