import React, {useContext} from 'react';
import {LinearProgress, Typography, Card, CardContent} from '@mui/material';
import ProgressContext from '../../contexts/ProgressContext';

const ProgressWidget = () => {
    const {progress, docCount, totalDocs, isUploading} = useContext(ProgressContext);
    return (
        isUploading && (
            <Card
                sx={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    width: 200,
                    backgroundColor: '#f1f1f1',
                    color: "#674f87",
                    borderRadius: 2,
                    zIndex: 1000,
                    // boxShadow: 3,
                    border: '2px solid #d8d8d8',
                }}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="body2"
                        align="center"
                        sx={{ marginBottom: 2, fontWeight:'bold' }}
                    >
                        Importing Files: {docCount}/{totalDocs}
                    </Typography>
                    {/* <Typography
                        variant="body2"
                        align="center"
                        sx={{ marginBottom: 2, fontWeight:'bold' }}
                    >
                        {docCount}/{docCount}
                    </Typography> */}
                    <LinearProgress 
                        variant="determinate" 
                        color='secondary'
                        value={progress} 
                        sx={{ width: '100%', height: 10, borderRadius: 5 }}
                    />
                    <Typography
                        variant="caption"
                        component="div"
                        sx={{ fontWeight: 'bold', marginTop: 1, color: "#674f87", }}
                    >
                        {`${Math.round(progress)}%`}
                    </Typography>
                </CardContent>
            </Card>
        )
    );
};

export default ProgressWidget;
