import { Padding } from "@mui/icons-material";

export const topmenuStyles = {
    top_menu: {
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#432F60',
        background: `linear-gradient(to right, #432F60, #9E3C5D)`,
        // background: 'linear-gradient(to right, #432F60, #9E3C5D )'
    },
    /*top_menu: {
        backgroundColor: '#3f51b5', // Change this color to your desired color
    },*/
    logo: {
        marginRight: 'auto',
        width: '10%',
        cursor: 'pointer',
    },
    userInfo: {
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
};