import React, {useState} from 'react';
import {
    Typography, TextField, Button, Box, FormControl,
    MobileStepper, IconButton, InputAdornment,
} from '@mui/material';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import LogoText from '../images/metadocs_text.svg';
import api from '../../API';
import Popper from '@mui/material/Popper';
import {useTranslation} from 'react-i18next';

const steps = ['User Information', 'Personal Details', 'Password Setup', 'Organization Details'];

function SignUpForm({switchToSignin, showSnackbar}) {
    const [formData, setFormData] = useState({
        username: '',
        firstName: '',
        lastName: '',
        password: '',
        email: '',
        organization: '',
        contact: '',
        confirmPassword: '',
        realm: process.env.REACT_APP_REALM,
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [activeStep, setActiveStep] = React.useState(0);
    const [stepValidation, setStepValidation] = useState({step0: false, step1: false, step2: false, step3: false,});
    const [usernameAnchor, setUsernameAnchor] = useState(null);
    const [emailAnchor, setEmailAnchor] = useState(null);
    const [passwordAnchor, setPasswordAnchor] = useState(null);
    const [confirmpasswordAnchor, setConfirmPasswordAnchor] = useState(null);
    const {t} = useTranslation();

    const HelperTextPopper = ({ open, anchorEl, text }) => {
        return (
            <Popper open={open} anchorEl={anchorEl} placement="bottom-start" sx={{width:450, lineHeight:0,}}>
                <Typography variant="caption" color="error"
                // sx={{padding:0.5, bgcolor: '#F3EFEF', borderRadius: 1, fontSize:12, color:'red' }}
                >
                    {text}
                </Typography>
            </Popper>
        );
    };
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === 'password') {
            validatePassword(e.target.value);
        }
        if (e.target.name === 'confirmPassword') {
            checkPasswordMatch(e.target.value);
        }
        if (e.target.name === 'email') {
            validateEmail(e.target.value);
        }
        if (e.target.name === 'username') {
            validateText(e.target.value);
        }
    };

    const handlePhoneChange = (value) => {
        setFormData({
            ...formData,
            contact: value,
        });
    };

    const validateText = (text) => {
        const minLength = 3;

        if (text.length < minLength) {
            setUsernameError(t('username _should _be_of_minimum_3_characters'));
        } else {
            setUsernameError('');
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError(t('please_enter_a_valid_email address.'));
        } else {
            setEmailError('');
        }
    };


    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*]/.test(password);
        const errormessage = t('password_helper_text');

        if (password.length < minLength) {
            setPasswordError(errormessage);
        } else if (!hasUpperCase) {
            setPasswordError(errormessage);
        } else if (!hasLowerCase) {
            setPasswordError(errormessage);
        } else if (!hasNumber) {
            setPasswordError(errormessage);
        } else if (!hasSpecialChar) {
            setPasswordError(errormessage);
        } else {
            setPasswordError('');
        }
    };

    const checkPasswordMatch = (confirmPassword) => {
        if (formData.password !== confirmPassword) {
            setConfirmPasswordError(t('passwords_do_not_match'));
        } else {
            setConfirmPasswordError('');
        }
    };


    const handleNext = () => {
        if (activeStep === 0) {
            if (formData.username && formData.email && !emailError) {
                setStepValidation({...stepValidation, step0: true});
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                setStepValidation({...stepValidation, step0: false});
            }
        } else if (activeStep === 1) {
            if (formData.firstName && formData.lastName) {
                setStepValidation({...stepValidation, step1: true});
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                setStepValidation({...stepValidation, step1: false});
            }
        } else if (activeStep === 2) {
            if (formData.password && formData.confirmPassword && !passwordError && !confirmPasswordError) {
                setStepValidation({...stepValidation, step2: true});
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                setStepValidation({...stepValidation, step2: false});
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleKeyPress = (e, nextInputName) => {
        if (e.key === 'Enter') {
            if (nextInputName) {
                const nextInput = document.getElementsByName(nextInputName)[0];
                if (nextInput) {
                    nextInput.focus();
                }
            } else {
                handleNext();
            }
        }
    };

    const handleSubmit = async () => {
        try {
            const response = await api.post('auth/create_user', formData);
            if (response.status === 200) {
                switchToSignin();
                showSnackbar(t('user_creation_succesfull'));
            } else {
                showSnackbar(t('user_creation_failed'));
            }
        } catch (error) {
            showSnackbar(error.message);
            // showSnackbar('User created. Please sign in with new user.');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
            <Box className="content" sx={{width: 450, height: 'auto',}}>
                <Typography variant="h3" gutterBottom sx={{marginInline:7.5, }}>
                    {t('create_account')}
                </Typography>
                <img src={LogoText} alt="login" className="loginText" style={{marginBottom: '16px', width: 450}}/>
                <FormControl id="loginForm">
                    {activeStep === 0 && (
                        <Box sx={{display: 'block', width: 450}}>
                            <TextField
                                fullWidth
                                label={t("username")}
                                name="username"
                                variant="outlined"
                                margin="normal"
                                value={formData.username}
                                onChange={handleChange}
                                onKeyPress={(e) => handleKeyPress(e, 'email')}
                                error={!!usernameError}
                                onFocus={(e) => setUsernameAnchor(e.currentTarget)}
                                sx={{
                                    marginBottom:3,
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: '#432F60',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#432F60',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        '&:hover': {
                                            color: '#432F60',
                                        },
                                        '&.Mui-focused': {
                                            color: '#432F60',
                                        },
                                    },
                                }}
                            />
                            <HelperTextPopper open={!!usernameError} anchorEl={usernameAnchor} text={usernameError} />
                            <TextField
                                fullWidth
                                label={t('email')}
                                name="email"
                                variant="outlined"
                                margin="normal"
                                value={formData.email}
                                onChange={handleChange}
                                onKeyPress={(e) => handleKeyPress(e, null)}
                                error={!!emailError}
                                onFocus={(e) => setEmailAnchor(e.currentTarget)}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: '#432F60',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#432F60',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        '&:hover': {
                                            color: '#432F60',
                                        },
                                        '&.Mui-focused': {
                                            color: '#432F60',
                                        },
                                    },
                                }}
                            />
                            <HelperTextPopper open={!!emailError} anchorEl={emailAnchor} text={emailError} />
                        </Box>
                    )}
                    {activeStep === 1 && (
                        <Box sx={{display: 'block', width: 450}}>
                            <TextField
                                fullWidth
                                label={t('first_name')}
                                name="firstName"
                                variant="outlined"
                                margin="normal"
                                value={formData.firstName}
                                onChange={handleChange}
                                onKeyPress={(e) => handleKeyPress(e, 'lastName')}
                                sx={{
                                    marginBottom:3,
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: '#432F60',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#432F60',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        '&:hover': {
                                            color: '#432F60',
                                        },
                                        '&.Mui-focused': {
                                            color: '#432F60',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                label={t('last_name')}
                                name="lastName"
                                variant="outlined"
                                margin="normal"
                                value={formData.lastName}
                                onChange={handleChange}
                                onKeyPress={(e) => handleKeyPress(e, null)}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: '#432F60',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#432F60',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        '&:hover': {
                                            color: '#432F60',
                                        },
                                        '&.Mui-focused': {
                                            color: '#432F60',
                                        },
                                    },
                                }}
                            />
                        </Box>
                    )}
                    {activeStep === 2 && (
                        <Box sx={{display: 'block', width: 450}}>
                            <TextField
                                fullWidth
                                label={t('password')}
                                name="password"
                                variant="outlined"
                                margin="normal"
                                type={showPassword ? 'text' : 'password'}
                                value={formData.password}
                                onChange={handleChange}
                                onKeyPress={(e) => handleKeyPress(e, 'confirmPassword')}
                                error={!!passwordError}
                                onFocus={(e) => setPasswordAnchor(e.currentTarget)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    marginBottom:3,
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: '#432F60',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#432F60',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        '&:hover': {
                                            color: '#432F60',
                                        },
                                        '&.Mui-focused': {
                                            color: '#432F60',
                                        },
                                    },
                                }}
                            />
                            <HelperTextPopper open={!!passwordError} anchorEl={passwordAnchor} text={passwordError} />
                            <TextField
                                fullWidth
                                label={t('confirm_password')}
                                name="confirmPassword"
                                variant="outlined"
                                margin="normal"
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                onKeyPress={(e) => handleKeyPress(e, null)}
                                error={!!confirmPasswordError}
                                onFocus={(e) => setConfirmPasswordAnchor(e.currentTarget)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle confirm password visibility"
                                                onClick={toggleConfirmPasswordVisibility}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: '#432F60',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#432F60',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        '&:hover': {
                                            color: '#432F60',
                                        },
                                        '&.Mui-focused': {
                                            color: '#432F60',
                                        },
                                    },
                                }}
                            />
                            <HelperTextPopper open={!!confirmPasswordError} anchorEl={confirmpasswordAnchor} text={confirmPasswordError} />
                        </Box>
                    )}
                    {activeStep === 3 && (
                        <Box sx={{display: 'block'}}>
                            <TextField
                                fullWidth
                                label={t('organization')}
                                name="organization"
                                variant="outlined"
                                margin="normal"
                                value={formData.organization}
                                onChange={handleChange}
                                sx={{
                                    marginBottom:3,
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: '#432F60',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#432F60',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        '&:hover': {
                                            color: '#432F60',
                                        },
                                        '&.Mui-focused': {
                                            color: '#432F60',
                                        },
                                    },
                                }}
                            />
                            <PhoneInput
                                country={'in'}
                                value={formData.contact}
                                onChange={handlePhoneChange}
                                containerStyle={{width: 450, marginTop: 16, background: 'inherit'}}
                                inputStyle={{width: 450, background: 'inherit', }}
                                // buttonStyle={{ height: 40 }}
                            />
                        </Box>
                    )}
                    <MobileStepper
                        variant="dots"
                        steps={steps.length}
                        position="relative"
                        activeStep={activeStep}
                        sx={{
                            width: 435,
                        marginTop: 3,
                            background: 'inherit',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        height: '60px',
                            '& .MuiMobileStepper-dotActive': {
                                backgroundColor: '#9E3C5D',
                            },
                            '& .MuiMobileStepper-dots': {
                                flexGrow: 1,
                                display: 'flex',
                                justifyContent: 'center',
                            },
                        }}
                        nextButton={
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '100%',
                                position: 'absolute',
                                right: 0,
                            }}
                        >
                            {activeStep === 3 ? (
                                formData.organization && formData.contact ? (
                                    <Button
                                        type="submit"
                                        className="submitbtn"
                                        variant="contained"
                                        size="small"
                                        onClick={handleSubmit}
                                        startIcon={<CheckCircleIcon/>}
                                        sx={{
                                            background: 'linear-gradient(315deg, #432F60 0%, #9E3C5D 60%)',
                                            ml: 2,
                                            maxWidth: 'fit-content',
                                        }}
                                    >
                                        {t('submit')}
                                    </Button>
                                ) : null
                            ) : (
                                ((activeStep === 0 && formData.username && formData.email && !emailError) ||
                                    (activeStep === 1 && formData.firstName && formData.lastName) ||
                                    (activeStep === 2 && formData.password && formData.confirmPassword && !passwordError && !confirmPasswordError)) && (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        endIcon={<KeyboardArrowRight/>}
                                        onClick={handleNext}
                                        sx={{
                                            background: 'linear-gradient(315deg, #432F60 0%, #9E3C5D 60%)',
                                            ml: 2,
                                            maxWidth: 'fit-content',
                                        }}
                                    >
                                        {t('next')}
                                    </Button>
                                )
                            )}
                        </div>
                        }
                        backButton={
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                width: '100%',
                                position: 'absolute',
                                left: 0,
                            }}
                        >
                            {activeStep > 0 && (
                                <Button
                                    variant="contained"
                                    size="small"
                                    startIcon={<KeyboardArrowLeft/>}
                                    onClick={handleBack}
                                    sx={{
                                        background: 'linear-gradient(315deg, #432F60 0%, #9E3C5D 60%)',
                                        mr: 2,
                                        maxWidth: 'fit-content',
                                    }}
                                >
                                    {t('back')}
                                </Button>
                            )}
                        </div>
                        }
                    />
                </FormControl>
                <Typography sx={{position: 'fixed', bottom: '5%', right: '19%'}}>
                    {t('already_have_an_account')}
                    <span>
                        <Button onClick={switchToSignin}
                                sx={{color: '#9E3C5D', fontWeight: 'bold'}}>{t('sign_in')}</Button>
                    </span>
                </Typography>
        </Box>
    );
}

export default SignUpForm;
