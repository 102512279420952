import React, {useState} from 'react';
import {Box,} from '@mui/material';
import {motion} from 'framer-motion';
import SignUpForm from './SignUpForm';
import LoginForm from './loginform';
import Logo from '../images/meta_grey.png';
import CustomSnackbar from "../../components/SnackBar/Snackbar";
import LanguageChanger from "../../components/Language/LanguageChanger";
import ForgotPassword from './forgotPassword';

const backdropVariants = {
    expanded: {
        width: '110%',
        borderRadius: '25px',

    },
    collapsed: {
        width: '30%',
        borderRadius: '25px 0 0 25px',
    }
}

const expandingTransition = {
    type: "spring",
    duration: 2.3,
    stiffness: 35,
}

const Login = ({onLogin}) => {
    const [snackbarOpen, setSnackBarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isExpanded, setExpanded] = useState(false);
    const [active, setActive] = useState('signin');
    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackBarOpen(true);
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };
    const playExpandingAnimation = () => {
        setExpanded(true);
        setTimeout(() => {
            setExpanded(false);
        }, expandingTransition.duration * 1000 - 1500);
    }
    const switchToSignup = () => {
        playExpandingAnimation();
        setTimeout(() => {
            setActive("signup");
        }, 400);
    }
    const switchToSignin = () => {
        playExpandingAnimation();
        setTimeout(() => {
            setActive("signin");
        }, 400);
    }

    const switchToForgetPassword = () => {
        playExpandingAnimation();
        setTimeout(() => {
            setActive("forgotPasssword");
        }, 400);
    }

    return (
        <>
            <CustomSnackbar open={snackbarOpen} message={snackbarMessage} onClose={handleCloseSnackbar}/>
            <Box className="main-container"
                 sx={{
                     width: {xs: '90%', sm: 600, md: 850},
                     height: {xs: 'auto', sm: 'auto', md: 550},
                     display: 'flex',
                     flexDirection: {xs: 'column', md: 'row'},
                     position: 'fixed',
                     top: '50%',
                     left: '50%',
                     transform: 'translate(-50%, -50%)',
                     background: '#f3efef',
                     borderRadius: '25px',
                     boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
                 }}
            >
                <motion.div className="backdrop"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: {xs: '100%', md: '30%'},
                                background: 'linear-gradient(315deg, #432F60 0%, #9E3C5D 50%)',
                                borderRadius: {xs: '25px', md: '25px 0 0 25px'},
                                zIndex: 1
                            }}
                            initial={false}
                            animate={isExpanded ? "expanded" : "collapsed"}
                            variants={backdropVariants}
                            transition={expandingTransition}

                >
                    <Box sx={{position: 'relative', top: {xs: '10%', md: '30%'}, left: {xs: '25%', md: '5%'}}}>
                        <img src={Logo} alt="logo" style={{width: {xs: '50%', md: '100%'}, height: '40%'}}/>
                    </Box>
                </motion.div>
                <Box className="right-container" sx={{background: '#f3efef'}}>
                    {active === "signin" &&
                        <Box sx={{position: 'fixed', top: '5%', left: '38.5%'}}>
                            <LoginForm onLogin={onLogin} switchToSignup={switchToSignup} switchToForgetPassword={switchToForgetPassword} showSnackbar={showSnackbar}/>
                        </Box>
                    }
                    {active === "signup" &&
                        <Box sx={{position: 'fixed', top: '5%', left: '38.5%'}}>
                            <SignUpForm switchToSignin={switchToSignin} showSnackbar={showSnackbar}/>
                        </Box>
                    }
                    {active === "forgotPasssword" &&
                        <Box sx={{position: 'fixed', top: '5%', left: '38.5%'}}>
                            <ForgotPassword switchToSignin={switchToSignin}/>
                        </Box>
                    }
                </Box>
            </Box>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    mb: 2, // Optional margin-bottom
                }}
            >
                <LanguageChanger defaultColor={"#432F60"}/>
            </Box>
        </>
    );
}
export default Login;
