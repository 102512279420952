import { createTheme } from '@mui/material/styles';

export const dashboardTheme = createTheme({
    components: {
        
    MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            borderRadius: '4px'
          }
        }
      },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '1.7rem',
                },
            },
        },
    },
    palette: {
        white: {
            main: '#fff',
        },
        secondary: {
            main: '#674f87',
          }
    },
    typography: {
        h1: {
            fontSize: '1.6rem',
            fontWeight: 600,
            color: '#fff',
            letterSpacing: '0.5px',
            textTransform: 'capitalize',
        },
    },
  });