import React, {useState} from 'react';
import {
    Drawer,
    Toolbar,
    Divider,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    IconButton,
    Button
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {MainNavbarItemsComponent, mainProfileItems} from './consts/navbarItems';
import {navbarStyles} from './styles';
import {useMediaQuery} from '@mui/material';
import {useAuth} from '../../contexts/AuthContext';
import {useLocation} from 'react-router-dom';

const Navbar = ({isDrawerOpen, isCollapsed, toggleDrawer}) => {
    const navigate = useNavigate();
    const isSmallerScreen = useMediaQuery('(max-width:1000px), (max-height:600px)');
    const {isAdmin, isSuperuser} = useAuth();
    const currentLocation = useLocation();
    const mainNavbarItems = MainNavbarItemsComponent();

    const handleItemClick = (route, id) => {
        navigate(route);
        if (isSmallerScreen) toggleDrawer();
    };

    const filteredNavbarItems = mainNavbarItems.filter(item => {
        if (isAdmin || isSuperuser) {
            return true;
        }
        return item.id === 1;
    });

    return (

        <Drawer
            sx={{...navbarStyles.drawer, width: isCollapsed ? 109 : 250}}
            variant={isSmallerScreen ? 'temporary' : 'permanent'}
            anchor="left"
            open={isSmallerScreen ? isDrawerOpen : true}
            onClose={toggleDrawer}
        >
            <Toolbar/>
            <Divider/>
            <Box sx={{mt: 12}}>
                <List>
                    {filteredNavbarItems.map((item, index) => (
                        <ListItem
                            sx={navbarStyles.list}
                            button
                            key={item.id}
                            onClick={() => handleItemClick(item.route, item.id)}
                            selected={currentLocation.pathname === `/${item.route}`}
                        >
                            {isCollapsed ? (
                                <Tooltip title={item.label}>
                                    <ListItemIcon
                                        sx={navbarStyles.icons}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                </Tooltip>
                            ) : (
                                <>
                                    <ListItemIcon
                                        sx={navbarStyles.icons}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        // sx={navbarStyles.text}
                                        primary={item.label}
                                    />
                                </>
                            )}
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Box sx={{position: 'absolute', bottom: 0, mt: 12}}>
                <List>
                    {mainProfileItems.map((item, index) => (
                        <ListItem
                            sx={navbarStyles.list}
                            button
                            key={item.id}
                            onClick={() => navigate(item.route)}
                        >
                            {isCollapsed ? (
                                <Tooltip title={item.label}>
                                    <ListItemIcon
                                        sx={navbarStyles.icons}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                </Tooltip>
                            ) : (
                                <>
                                    <ListItemIcon
                                        sx={navbarStyles.icons}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        // sx={navbarStyles.text}
                                        primary={item.label}
                                    />
                                </>
                            )}
                        </ListItem>
                    ))}
                </List>
                {/* {!isMediumScreen && (
                    <Button onClick={toggleCollapse} hidden={false}> Toggle</Button>
                )} */}
            </Box>
        </Drawer>
    );
};

export default Navbar;
