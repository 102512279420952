import React, { useState } from 'react';
import Button from '@mui/material/Button';
import GradientIcon from './grad-icon'


const GradButton = ({ header,header_icon,handleClick }) => {
  return (
    <div>
      <Button variant="outlined" size="small" sx={{width: '100%'}} onClick={handleClick}  color="secondary" startIcon={<GradientIcon IconComponent={header_icon} />}>
        {header} 
      </Button>
    </div>
  );
};

export default GradButton;