import React, {useEffect, useState, useMemo, useCallback} from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import {Box, Button, Tooltip, styled, tooltipClasses} from '@mui/material';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';
import DocumentThumbnail from "../../components/Document/DocumentThumbnail";
import {useTranslation} from 'react-i18next';


const CustomTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#d8d8d8',
        color: '#674f87',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const Folder = ({name, onDoubleClick, folderLevel, handleFolderLevel}) => {
    const [open, setOpen] = useState(false);

    const handleDoubleClick = () => {
        setOpen(!open);
        onDoubleClick && onDoubleClick();
        handleFolderLevel(folderLevel + 1);
    };

    const truncateName = (name, length) => {
        return name.length > length ? `${name.substring(0, length)}...` : name;
    };

    return (
        <Box
            onDoubleClick={handleDoubleClick}
            sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '1px',
                borderRadius: '4px',
            }}
        >
            <CustomTooltip title={name}>
                <Box
                    sx={{
                        display: 'flex',
                        width: '200px',
                        alignItems: 'center',
                        borderRadius: '4px',
                        '&:hover': {backgroundColor: '#fcdce0'},
                    }}
                >
                    <FolderIcon sx={{color: '#ef798a', fontSize: '64px'}}/>
                    <Typography
                        variant="body1"
                        sx={{
                            marginInline: '8px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            maxWidth: '120px',
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                            maxHeight: '4em',
                            overflowY: 'auto',
                        }}
                    >
                        {truncateName(name, 12)}
                    </Typography>
                </Box>
            </CustomTooltip>
        </Box>
    );
};

const organizeData = (documents, level = 0) => {
    const data = {};

    documents.forEach(doc => {
        if (level === 0) {
            if (!data[doc.document_type]) {
                data[doc.document_type] = {};
            }
        } else if (level === 1 || level === 2) {
            if (!data[doc.document_super_name]) {
                data[doc.document_super_name] = [];
            }
            const documentIdsArray = doc.document_ids.split(',').map(Number);
            data[doc.document_super_name].push(...documentIdsArray);
        }
    });
    return data;
};

const FolderView = ({
                        docs,
                        handleItem,
                        downloadPdf,
                        downloadLoading,
                        handleDeleteOpen,
                        deleteOpen,
                        handleDeleteClose,
                        deleteDocument,
                        deleteLoading,
                        handleFolderLevel,
                        folderLevel,
                        handleCheckboxChange,
                        selectedDocuments,
                        handleDocsReload,
                        folderNavigateTo,
                        folderPathSlicer,
                        folderPath,
                    }) => {
    const {t} = useTranslation();

    const updatedData = useMemo(() => organizeData(docs, folderLevel), [docs, folderLevel]);

    const handleDoubleClick = (path, index) => {
        folderNavigateTo(path, index);
    };

    const changeFolderLevel = (level) => {
        handleFolderLevel(level);
        folderPathSlicer(level);
    }

    useEffect(() => {
        handleDocsReload(true);
    }, [folderLevel]);

    const renderContents = (updatedData) => {
        if (!updatedData) return null;

        const currentDocuments = updatedData[folderPath[1]] || [];

        switch (folderLevel) {
            case 0:
                return Object.keys(updatedData).map(id => (
                    <Folder
                        key={id}
                        name={id}
                        onDoubleClick={() => handleDoubleClick(id, 0)}
                        folderLevel={folderLevel}
                        handleFolderLevel={handleFolderLevel}
                    />

                ));
            case 1:
                return Object.keys(updatedData).map(id => (
                    <Folder
                        key={id}
                        name={id}
                        onDoubleClick={() => handleDoubleClick(id, folderLevel)}
                        folderLevel={folderLevel}
                        handleFolderLevel={handleFolderLevel}
                    />
                ));
            case 2:
                return currentDocuments.map(file => (
                    <DocumentThumbnail
                        key={file}
                        documentId={file}
                        document={null}
                        handleItem={handleItem}
                        downloadPdf={downloadPdf}
                        downloadLoading={downloadLoading}
                        handleDeleteOpen={handleDeleteOpen}
                        deleteOpen={deleteOpen}
                        handleDeleteClose={handleDeleteClose}
                        deleteDocument={deleteDocument}
                        deleteLoading={deleteLoading}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedDocuments={selectedDocuments}
                    />
                ));
            default:
                return null;
        }
    };

    const handleBreadCrumb = (event) => {
        event.preventDefault();
    };

    const truncateName = useCallback((name, length) => {
        return name.length > length ? `${name.substring(0, length)}...` : name;
    }, []);

    return (
        <GridWrapper sx={{paddingTop: '10px'}}>
            <Box
                sx={{
                    marginTop: 2,
                    marginBottom: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    width: '80%',
                    background: '#ffffff',
                }}
            >
                {folderPath.length > 0 && (
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => changeFolderLevel(folderLevel - 1)}
                        color="secondary"
                        startIcon={<ArrowLeftIcon/>}
                    >
                        {t('back')}
                    </Button>
                )}

                {folderPath.length > 0 && (
                    <Box onClick={handleBreadCrumb}>
                        <Breadcrumbs aria-label="breadcrumb" sx={{fontWeight: 'bold', color: '#ef798a'}}>
                            <Link
                                underline="hover"
                                color="inherit"
                                onClick={() => changeFolderLevel(0)}
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#674f87',
                                    '&:hover': {color: '#ef798a'},
                                    cursor: 'pointer',
                                    margin: '0 8px',
                                }}
                            >
                                {t('documents')}
                            </Link>
                            {folderPath.map((path, index) => (
                                <CustomTooltip title={path} key={path}>
                                    <Link
                                        underline="hover"
                                        color="inherit"
                                        onClick={() => changeFolderLevel(index + 1)}
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#674f87',
                                            '&:hover': {color: '#ef798a'},
                                            cursor: 'pointer',
                                            margin: '0 8px',
                                        }}
                                    >
                                        {truncateName(path, 12)}
                                    </Link>
                                </CustomTooltip>
                            ))}
                        </Breadcrumbs>
                    </Box>
                )}
            </Box>

            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1, overflowInline: 'hidden', marginTop: 2}}>
                {renderContents(updatedData)}
            </Box>
        </GridWrapper>
    );
};

export default FolderView;
