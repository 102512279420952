import React from 'react';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';

export default function WhatsNew() {
    const [value, setValue] = React.useState('1');
    return (
        <GridWrapper>
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={value}>
                </TabContext>
            </Box>
        </GridWrapper>
    );
};