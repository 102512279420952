import React, {useState, useEffect} from "react";
import {
    Grid,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    CircularProgress
} from "@mui/material";
import {format} from "date-fns";
import {Link as RouterLink} from "react-router-dom"; // For routing
import api from "../../API";
import {useTranslation} from 'react-i18next';


const getStatusStyle = (status) => {
    switch (status) {
        case "completed":
            return {color: "green", fontWeight: "bold"};
        case "pending":
            return {color: "pending", fontWeight: "bold"};
        case "started":
            return {color: "orange", fontWeight: "bold"};
        case "failed":
            return {color: "red", fontWeight: "bold"};
        default:
            return {color: "gray", fontWeight: "bold"};
    }
};

const RecentFilesTable = () => {
    const [recentFiles, setRecentFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const {t} = useTranslation();


    useEffect(() => {
        const fetchRecentFiles = async () => {
            try {
                const params = {
                    order_by: "DESC",
                    latest: true,
                    limit: 5
                };
                const response = await api.get("/data/documents", {params});
                setRecentFiles(response.data.results);
            } catch (error) {
                setError(error.response ? error.response.data.message : "Failed to fetch recent files.");
            } finally {
                setLoading(false);
            }
        };

        fetchRecentFiles();
    }, []);

    if (loading) {
        return (
            <Grid container justifyContent="center">
                <CircularProgress/>
            </Grid>
        );
    }

    if (error) {
        return (
            <Grid container justifyContent="center">
                <Typography color="error">{error}</Typography>
            </Grid>
        );
    }
    const truncateName = ((name, length) => {
        return name.length > length ? `${name.substring(0, length)}...` : name;
    });

    return (
        <Grid item xs={12}>
            <Paper sx={{padding: 2}}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">{t('recent_files')}</Typography>
                    <Link component={RouterLink} to="/documents" underline="none"
                          sx={{fontWeight: 'bold', color: '#ef798a'}}>
                        {t('view_all')}
                    </Link>
                </Grid>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('file_name')}</TableCell>
                                <TableCell>{t('uploaded')}</TableCell>
                                <TableCell>{t('type')}</TableCell>
                                {/*<TableCell>Status</TableCell>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {recentFiles.map((file, index) => (
                                <TableRow key={index}>
                                    <TableCell>{truncateName(file.document_super_name, 20)}</TableCell>
                                    <TableCell>{format(new Date(file.document_created_on), 'MM/dd/yyyy')}</TableCell>
                                    <TableCell>{file.document_type}</TableCell>
                                    <TableCell>
                                        <span style={getStatusStyle(file.classification_merge_stat)}>
                                            {file.classification_merge_stat}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid>
    );
};

export default RecentFilesTable;
