import React, {useState, useEffect} from 'react';
import {
    Typography,
    TextField,
    Button,
    IconButton,
    InputAdornment,
    Box,
    FormControl
} from '@mui/material';
import {useAuth} from '../../contexts/AuthContext';
import {useNavigate} from 'react-router-dom';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import LogoText from '../images/metadocs_text.svg';
import LoadingButton from '../../components/Buttons/LoadingButton';
import {useTranslation} from 'react-i18next';

function LoginForm({onLogin, switchToSignup, switchToForgetPassword, showSnackbar}) {
    const {login, error} = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({username: '', password: '', realm: process.env.REACT_APP_REALM});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [authenticationError, setAuthenticationError] = useState("");
    const {t} = useTranslation();

    useEffect(() => {
        setAuthenticationError(error);
        console.error("Updated authenticationError:", error);
    }, [error]);

    useEffect(() => {
        const logoutMessage = localStorage.getItem('logout_message');
        if (logoutMessage) {
            showSnackbar(logoutMessage);
            localStorage.removeItem('logout_message');
        }
        const refresh = localStorage.getItem('refreshToken');
        const realm = localStorage.getItem('realm');
        if (refresh && realm && localStorage.getItem('isLoggedIn')) {
            navigate('/');
        }
    }, [navigate, showSnackbar]);

    const handleLogin = async (e) => {
        // e.preventDefault();
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length === 0) {
            setLoading(true);
            const loginState = await login(formData);
            setLoading(false);
            if (loginState && authenticationError == null) {
                onLogin();
                navigate('/');
            } else {
                setErrors({global: error});
            }
        } else {
            setErrors(validationErrors);
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        setErrors({});
        setAuthenticationError(null);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.username) errors.username = t('username_is_required');
        if (!data.password) errors.password = t('password_is_required');
        if (!data.realm) errors.realm = 'Realm is required';
        return errors;
    };
    const handleEnterKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <Box className="right-half">
            <Box className="content"
                 sx={{display: 'block', width: 'auto', height: 'auto', justifyItems: 'center', alignItems: 'center'}}>
                <FormControl id="loginForm">
                    <Box sx={{display: 'block', width: {xs: '100%', md: 450}}}>
                        <Typography variant="h3" gutterBottom sx={{width: '100%', textAlign: 'center'}}>
                            {t('welcome')}
                        </Typography>
                        <img src={LogoText} alt="login" className="loginText"
                             style={{marginBottom: '16px', width: '100%'}}/>
                        <TextField
                            fullWidth
                            label={t('username')}
                            name="username"
                            variant="outlined"
                            margin="normal"
                            value={formData.username}
                            onChange={handleChange}
                            onKeyDown={handleEnterKeyPress}
                            error={!!errors.username}
                            helperText={errors.username}
                            sx={{
                                marginBottom: 3,
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: '#432F60',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#432F60',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    '&:hover': {
                                        color: '#432F60',
                                    },
                                    '&.Mui-focused': {
                                        color: '#432F60',
                                    },
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            label={t('password')}
                            name="password"
                            variant="outlined"
                            margin="normal"
                            type={showPassword ? 'text' : 'password'}
                            value={formData.password}
                            onChange={handleChange}
                            onKeyDown={handleEnterKeyPress}
                            error={!!errors.password}
                            helperText={errors.password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: '#432F60',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#432F60',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    '&:hover': {
                                        color: '#432F60',
                                    },
                                    '&.Mui-focused': {
                                        color: '#432F60',
                                    },
                                },
                            }}
                        />
                    </Box>
                    {errors.global ? (
                        <Typography color="error" padding={0} sx={{textAlign: 'center'}}>{errors.global}</Typography>
                    ) : authenticationError && (
                        <Typography color="error" padding={0}
                                    sx={{textAlign: 'center'}}>{authenticationError}</Typography>
                    )}
                    <LoadingButton
                        isLoading={loading}
                        onClick={handleLogin}
                        circleSize={20}
                        circleColor={'#fff'}
                        disabled={loading}
                        sx={{
                            background: 'linear-gradient(315deg, #432F60 0%, #9E3C5D 60%)',
                            mt: 2,
                            borderRadius: 5,
                            color: '#fff',
                            width: {xs: '100%', md: 450},
                        }}
                    >
                        {loading ? (<span style={{color: '#fff'}}>{t('logging_in')}</span>) : t('sign_in')}
                    </LoadingButton>
                </FormControl>
                <Typography
                            sx={{color: '#9E3C5D', cursor: 'pointer', marginTop: 2, textAlign: 'center'}}>
                    {/*{t('forgot_password')}*/}
                </Typography>
                <Typography sx={{textAlign: 'center', marginTop: 0}}>
                    {t('dont_you_have_an_account')}{' '}
                    <Button onClick={switchToSignup} sx={{color: '#9E3C5D', fontWeight: 'bold'}}>
                        {t('sign_up')}
                    </Button>
                </Typography>
            </Box>
        </Box>
    );
}

export default LoginForm;
