import React, {createContext, useState, useEffect} from 'react';
import api from '../API';

const ProgressContext = createContext();

export const ProgressProvider = ({children}) => {
    const [progress, setProgress] = useState(0);
    const [docCount, setDocCount] = useState(0);
    const [totalDocs, setTotalDocs] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [isDelayed, setIsDelayed] = useState(false);
    const [uploadStartTime, setUploadStartTime] = useState(null); // Store the started time

    const getAdjustedStartTime = (startTime) => {
        const localDate = new Date(startTime);

        localDate.setMinutes(localDate.getMinutes() - 2);

        const year = localDate.getUTCFullYear();
        const month = String(localDate.getUTCMonth() + 1).padStart(2, '0');
        const day = String(localDate.getUTCDate()).padStart(2, '0');
        const hours = String(localDate.getUTCHours()).padStart(2, '0');
        const minutes = String(localDate.getUTCMinutes()).padStart(2, '0');
        const seconds = String(localDate.getUTCSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const fetchProgress = async (startTime) => {
        try {
            const adjustedStartTime = getAdjustedStartTime(startTime);
            const params = {startTime: adjustedStartTime};
            const response = await api.get('progress/import_progress', {params});
            setProgress(response.data.progress);
            setDocCount(response.data.docCount);
            setTotalDocs(response.data.totalDocs);
            if (response.data.progress >= 100) {
                setIsUploading(false);
            }
        } catch (error) {
            console.error('Error fetching progress:', error);
        }
    };

    useEffect(() => {
        let interval;
        if (isUploading && isDelayed) {
            interval = setInterval(() => {
                fetchProgress(uploadStartTime);
            }, 2000);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isUploading, isDelayed, uploadStartTime]);

    const startUploadProgress = () => {
        const startTime = new Date().toISOString();
        setUploadStartTime(startTime); // Set the started time
        setIsUploading(true);
        setProgress(0);
        setTimeout(() => {
            setIsDelayed(true);
            fetchProgress(startTime);
        }, 10000);
    };

    return (
        <ProgressContext.Provider value={{progress, docCount, totalDocs, isUploading, startUploadProgress}}>
            {children}
        </ProgressContext.Provider>
    );
};

export default ProgressContext;
