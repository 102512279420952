import React, {useState, useEffect} from 'react';
import {
    Typography,
    TextField,
    Button,
    IconButton,
    InputAdornment,
    Box,
    FormControl,
    Grid
} from '@mui/material';
import {useAuth} from '../../contexts/AuthContext';
import {useNavigate} from 'react-router-dom';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import LogoText from '../images/metadocs_text.svg';
import LoadingButton from '../../components/Buttons/LoadingButton';
import {useTranslation} from 'react-i18next';

function ForgotPassword({switchToSignin}) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({email: '', password: '', confirmNewPassword: '',});
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const [switchToOTP, setSwitchToOTP] = useState(false);
    const [switchToNewPassword, setSwitchToNewPassword] = useState(false);
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');
    const [passwordAnchor, setPasswordAnchor] = useState(null);
    const [confirmNewPasswordAnchor, setConfirmNewPasswordAnchor] = useState(null);

    // useEffect(() => {
    //     const logoutMessage = localStorage.getItem('logout_message');
    //     if (logoutMessage) {
    //         showSnackbar(logoutMessage);
    //         localStorage.removeItem('logout_message');
    //     }
    //     const refresh = localStorage.getItem('refreshToken');
    //     const realm = localStorage.getItem('realm');
    //     if (refresh && realm && localStorage.getItem('isLoggedIn')) {
    //         navigate('/');
    //     }
    // }, [navigate, showSnackbar]);

    // const handleLogin = async (e) => {
    //     // e.preventDefault();
    //     const validationErrors = validateForm(formData);
    //     if (Object.keys(validationErrors).length === 0) {
    //         setLoading(true);
    //         const loginState = await login(formData);
    //         setLoading(false);
    //         if (loginState && authenticationError == null) {
    //             onLogin();
    //             navigate('/');
    //         } else {
    //             setErrors({global: error});
    //         }
    //     } else {
    //         setErrors(validationErrors);
    //     }
    // };

    const handleOtpEnter = (e) => {
        setSwitchToOTP(true);
    };

    const handleResetPassword = (e) => {
        setSwitchToNewPassword(true);
    };

    const handleChangePassword = (e) => {

    };



    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        // setErrors({});
        // setAuthenticationError(null);
    };


    const handleOtpChange = (e, index) => {
        const { value } = e.target;
        if (/^\d*$/.test(value)) { // Allow only numeric values
          const newOtp = [...otp];
          newOtp[index] = value.slice(-1); // Get the last character typed
          setOtp(newOtp);
          // Focus on the next field if available
          if (value && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
          }
        }
      };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmNewPasswordVisibility = () => {
        setShowConfirmNewPassword(!showConfirmNewPassword);
    };

    // const togglePasswordVisibility = () => {
    //     setShowPassword(!showPassword);
    // };

    // const validateForm = (data) => {
    //     const errors = {};
    //     if (!data.username) errors.username = t('username_is_required');
    //     if (!data.password) errors.password = t('password_is_required');
    //     if (!data.realm) errors.realm = 'Realm is required';
    //     return errors;
    // };
    // const handleEnterKeyPress = (event) => {
    //     if (event.key === 'Enter') {
    //         handleLogin();
    //     }
    // };

    return (
        <Box className="right-half">
            <Box className="content"
                sx={{display: 'block', width: 'auto', height: 'auto', justifyItems: 'center', alignItems: 'center'}}>
                {switchToNewPassword ? (
                <FormControl id="resetPassword">
                    <Box sx={{display: 'block', width: 450}}>
                        <img src={LogoText} alt="login" className="loginText"
                            style={{marginBottom: '16px', width: '100%'}}/>
                        <Typography variant="h4" gutterBottom sx={{width: '100%', textAlign: 'center'}}>
                            Reset Your Password
                        </Typography>
                        {/* <Typography variant="body1" gutterBottom sx={{width: '100%', textAlign: 'center'}}>
                            Please enter the 6-digit code sent to your email. 
                        </Typography> */}
                        <TextField
                            fullWidth
                            label='New Password'
                            name="password"
                            variant="outlined"
                            margin="normal"
                            type={showPassword ? 'text' : 'password'}
                            value={formData.password}
                            onChange={handleChange}
                            // onKeyPress={(e) => handleKeyPress(e, 'confirmPassword')}
                            error={!!passwordError}
                            onFocus={(e) => setPasswordAnchor(e.currentTarget)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                marginBottom:3,
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: '#432F60',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#432F60',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    '&:hover': {
                                        color: '#432F60',
                                    },
                                    '&.Mui-focused': {
                                        color: '#432F60',
                                    },
                                },
                            }}
                        />
                        {/* <HelperTextPopper open={!!passwordError} anchorEl={passwordAnchor} text={passwordError} /> */}
                        <TextField
                            fullWidth
                            label='Confirm New Password'
                            name="confirmNewPassword"
                            variant="outlined"
                            margin="normal"
                            type={showConfirmNewPassword ? 'text' : 'password'}
                            value={formData.confirmNewPassword}
                            onChange={handleChange}
                            // onKeyPress={(e) => handleKeyPress(e, null)}
                            error={!!confirmNewPasswordError}
                            onFocus={(e) => setConfirmNewPasswordAnchor(e.currentTarget)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={toggleConfirmNewPasswordVisibility}
                                            edge="end"
                                        >
                                            {showConfirmNewPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: '#432F60',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#432F60',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    '&:hover': {
                                        color: '#432F60',
                                    },
                                    '&.Mui-focused': {
                                        color: '#432F60',
                                    },
                                },
                            }}
                        />
                        {/* <HelperTextPopper open={!!confirmPasswordError} anchorEl={confirmpasswordAnchor} text={confirmPasswordError} /> */}
                    </Box>
                    <LoadingButton
                        isLoading={loading}
                        onClick={handleChangePassword}
                        circleSize={20}
                        circleColor={'#fff'}
                        disabled={loading}
                        sx={{
                            background: 'linear-gradient(315deg, #432F60 0%, #9E3C5D 60%)',
                            mt: 4,
                            borderRadius: 5,
                            color: '#fff',
                            width: {xs: '100%', md: 450},
                        }}
                    >
                        {loading ? (<span style={{color: '#fff'}}>'Loading...'</span>) : 'Change Password'}
                    </LoadingButton>                
                </FormControl>    
                ) : switchToOTP ? (   
                <FormControl id="otp">
                    <Box sx={{display: 'block', width: {xs: '100%', md: 450}}}>
                        <img src={LogoText} alt="login" className="loginText"
                            style={{marginBottom: '16px', width: '100%'}}/>
                        <Typography variant="h4" gutterBottom sx={{width: '100%', textAlign: 'center'}}>
                            Check your email
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{width: '100%', textAlign: 'center'}}>
                            Please enter the 6-digit code sent to your email. 
                        </Typography>
                        <Grid container spacing={2} sx={{justifyContent: 'center', marginTop: 2}}>
                            {otp.map((_, index) => (
                                <Grid item key={index}>
                                    <TextField
                                        id={`otp-input-${index}`}
                                        variant="outlined"
                                        value={otp[index]}
                                        onChange={(e) => handleOtpChange(e, index)}
                                        inputProps={{
                                            maxLength: 1,
                                            style: { textAlign: 'center' },
                                        }}
                                        sx={{ width: '60px',
                                            '& .MuiOutlinedInput-root': {
                                                '&:hover fieldset': {
                                                    borderColor: '#432F60',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#432F60',
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                '&:hover': {
                                                    color: '#432F60',
                                                },
                                                '&.Mui-focused': {
                                                    color: '#432F60',
                                                },
                                            },
                                        }}
                                    />
                                </Grid> 
                            ))}
                        </Grid>      
                    </Box>
                    <LoadingButton
                        isLoading={loading}
                        onClick={handleResetPassword}
                        circleSize={20}
                        circleColor={'#fff'}
                        disabled={loading}
                        sx={{
                            background: 'linear-gradient(315deg, #432F60 0%, #9E3C5D 60%)',
                            mt: 4,
                            borderRadius: 5,
                            color: '#fff',
                            width: {xs: '100%', md: 450},
                        }}
                    >
                        {loading ? (<span style={{color: '#fff'}}>'Loading...'</span>) : 'Submit'}
                    </LoadingButton>
                </FormControl>
                ) : (    
                <FormControl id="forgotPassword">
                    <Box sx={{display: 'block', width: {xs: '100%', md: 450}}}>
                    <img src={LogoText} alt="login" className="loginText"
                            style={{marginBottom: '16px', width: '100%'}}/>
                        <Typography variant="h4" gutterBottom sx={{width: '100%', textAlign: 'left'}}>
                            Forgot Password ?
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{width: '100%', textAlign: 'left'}}>
                            Enter the email associated with your account and we'll send you password reset instructions. 
                        </Typography>    
                        <TextField
                            fullWidth
                            label="E-mail"
                            name="email"
                            variant="outlined"
                            margin="normal"
                            value={formData.email}
                            onChange={handleChange}
                            // onKeyDown={handleEnterKeyPress}
                            sx={{
                                marginBottom: 3,
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: '#432F60',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#432F60',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    '&:hover': {
                                        color: '#432F60',
                                    },
                                    '&.Mui-focused': {
                                        color: '#432F60',
                                    },
                                },
                            }}
                        />
                    </Box>
                    <LoadingButton
                        isLoading={loading}
                        onClick={handleOtpEnter}
                        // onClick={switchToSignin}
                        circleSize={20}
                        circleColor={'#fff'}
                        disabled={loading}
                        sx={{
                            background: 'linear-gradient(315deg, #432F60 0%, #9E3C5D 60%)',
                            mt: 2,
                            borderRadius: 5,
                            color: '#fff',
                            width: {xs: '100%', md: 450},
                        }}
                    >
                        {loading ? (<span style={{color: '#fff'}}>'Loading...'</span>) : 'Reset Password'}
                    </LoadingButton>
                </FormControl>
            )}    
            </Box>
        </Box>
    );
}

export default ForgotPassword;
