import React, {useState, useEffect} from 'react';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import {Icon} from '@mui/material';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import {useTranslation} from 'react-i18next';


import GradientIcon from './grad-icon'

const HeadingTagsPopover = ({tags, header, header_icon, onChangeFunction, selectedTags, tagSearchChange}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const {t} = useTranslation();


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        tagSearchChange(event.target.value);
    };

    const handleTagSelect = (tag) => {
        if (selectedTags.some((t) => t.tag_id === tag.tag_id)) {
            onChangeFunction({selectedTags: selectedTags.filter((t) => t.tag_id !== tag.tag_id)});
        } else {
            onChangeFunction({selectedTags: [...selectedTags, tag]});
        }
    };

    const filteredTags = tags.filter((tag) =>
        tag.tag_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <Button variant="outlined" size="small" onClick={handleClick} color="secondary"
                    startIcon={<GradientIcon IconComponent={header_icon}/>}>
                {header}
                <Icon><GradientIcon IconComponent={ArrowDropDownOutlinedIcon}/></Icon>
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <TextField
                    label={t('search_tags')}
                    variant="outlined"
                    value={searchTerm}
                    color='secondary'
                    size='small'
                    onChange={handleSearchChange}
                    style={{margin: '16px'}}
                />

                <div style={{maxHeight: '200px', overflowY: 'auto'}}>
                    <List dense>
                        {filteredTags.map((tag) => (
                            <ListItem key={tag.tag_id} button onClick={() => handleTagSelect(tag)}>
                                <Checkbox
                                    checked={selectedTags.some((t) => t.tag_id === tag.tag_id)}
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                    color='secondary'
                                />
                                <ListItemText primary={tag.tag_name} color='secondary'/>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Popover>
        </div>
    );
};

export default HeadingTagsPopover;