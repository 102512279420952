import React, {useState, useEffect, useRef, useContext} from 'react';
import {useDropzone} from 'react-dropzone';
import uploadIcon from './icons/Upload.svg';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../API';
import {useNavigate} from 'react-router-dom';
import {Box, CircularProgress} from '@mui/material';
import CustomSnackbar from '../../components/SnackBar/Snackbar';
import ProgressContext from '../../contexts/ProgressContext';
import {useTranslation} from 'react-i18next';


const DropBox = ({setActiveTab}) => {
    const {startUploadProgress} = useContext(ProgressContext);
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [showDropzone, setShowDropzone] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [snackbarOpen, setSnackBarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const {t} = useTranslation();


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    const popupRef = useRef(null);

    const onDrop = (acceptedFiles) => {
        setFiles((prevFiles) =>
            prevFiles.concat(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        );
        setShowDropzone(false);
    };

    const handleFileClick = (file) => {
        setSelectedFile(file);
        setPopupVisible(true);
    };

    const handleDelete = () => {
        setFiles((prevFiles) => prevFiles.filter((file) => file !== selectedFile));
        setPopupVisible(false);
    };

    const handleReselectFiles = () => {
        setFiles([]);
        setShowDropzone(true);
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setPopupVisible(false);
        }
    };

    useEffect(() => {
        if (files.length === 0) {
            setShowDropzone(true);
        }
    }, [files]);

    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackBarOpen(true);
    };

    const uploadFiles = async () => {
        const maxSize = process.env.REACT_APP_MAX_UPLOAD_SIZE_IN_MB;
        const waitTime = process.env.REACT_APP_MAX_WAIT_TIME_IN_MINUTE;
        if (files.length === 0) {
            showSnackbar(t('select_a_file_to_upload'));
        } else {
            const totalSize = files.reduce((acc, file) => acc + file.size, 0);

            if (totalSize > maxSize * 1024 * 1024) {
                showSnackbar(t('total_size_exceeds_limit').replace('${maxSize}', maxSize));
            } else {
                const fileData = files.map((file) => {
                    // console.log("File:", file);
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = () => resolve({name: file.name, type: file.type, data: reader.result});
                        reader.onerror = reject;
                        reader.readAsDataURL(file);
                    });
                });

                try {
                    setIsLoading(true);
                    const filesAsJson = await Promise.all(fileData);
                    const response = await api.post('data/document_upload', {
                        files: filesAsJson,
                    }, {timeout: waitTime * 60000});
                    setIsLoading(false);
                    showSnackbar(t('files_uploaded_successfully'));
                    startUploadProgress();
                    setFiles([]);
                    setShowDropzone(true);
                } catch (error) {
                    // Check for 413 error
                    if (error.response && error.response.status === 413) {
                        showSnackbar(`Error uploading files: ${error.response.data.error} available space left in MB: ${error.response.data.available_space}`);
                        setIsLoading(false);
                    } else{
                    alert(`Error uploading files: ${error.message}`);
                        setIsLoading(false);
                    }
                }
            }
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const acceptedFormats = process.env.REACT_APP_UPLOAD_FORMATS
        ? JSON.parse(process.env.REACT_APP_UPLOAD_FORMATS)
        : {'application/pdf': [], 'image/jpeg': [], 'image/png': []};

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: acceptedFormats,
    });


    const renderDropzone = () => (
        <div
            {...getRootProps({className: 'dropzone'})}
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <input {...getInputProps()} />
            <img
                src={uploadIcon}
                alt="Upload"
                style={{
                    width: '150px',
                    height: '150px',
                    paddingLeft: '10px',
                }}
            />
            <p style={{alignItems: 'center', padding: '0px'}}>
                {t('drag_and_drop_or_click_to_select_files')}
            </p>
        </div>
    );

    const handleCloseClick = () => {
        navigate('/documents'); // Navigate to Dashboard page
    };

    const renderFiles = () => (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    padding: '10px',
                }}
            >
                {files.map((file) => (
                    <div
                        key={file.name}
                        style={{
                            marginBottom: '10px',
                            textAlign: 'center',
                            position: 'relative',
                        }}
                        onClick={() => handleFileClick(file)}
                    >
                        {file.type === 'application/zip' ? (
                            <div
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '10px',
                                    border: '1px solid #ccc',
                                    position: 'relative',
                                }}
                            >
                                <FolderZipIcon style={{fontSize: '98px', color: '#674f87'}}/>
                                <div
                                    ref={popupRef}
                                    style={{
                                        position: 'absolute',
                                        bottom: '0',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: '#fff',
                                        padding: '2px 5px',
                                        borderRadius: '3px',
                                        border: '1px solid #ccc',
                                        fontSize: '12px',
                                        display: popupVisible ? 'block' : 'none',
                                    }}
                                    className="file-name"
                                >
                                    {file.name}
                                </div>
                            </div>
                        ) : (file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ? (
                            <div
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '10px',
                                    border: '1px solid #ccc',
                                    position: 'relative',
                                }}
                            >
                                <PictureAsPdfIcon style={{fontSize: '98px', color: '#674f87'}}/>
                                <div
                                    ref={popupRef}
                                    style={{
                                        position: 'absolute',
                                        bottom: '0',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: '#fff',
                                        padding: '2px 5px',
                                        borderRadius: '3px',
                                        border: '1px solid #ccc',
                                        fontSize: '12px',
                                        display: popupVisible ? 'block' : 'none',
                                    }}
                                    className="file-name"
                                >
                                    {file.name}
                                </div>
                            </div>
                        ) : (
                            <img
                                src={file.preview}
                                alt={file.name}
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    objectFit: 'cover',
                                    borderRadius: '10px',
                                }}
                            />
                        )}
                    </div>
                ))}
            </div>
            <div
                style={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'flex-end',
                    padding: '10px',
                    position: 'sticky',
                    bottom: '0',
                    backgroundColor: '#fff',
                }}
            >
                <button
                    onClick={handleReselectFiles}
                    style={{
                        padding: '10px 12px',
                        color: '#fff',
                        background: 'linear-gradient(to right, #ef798a, #674f87)',
                        borderRadius: '5px',
                        border: '1px solid #f1f1f1',
                        cursor: 'pointer',
                    }}
                >
                    {t('reselect_files')}
                </button>
                <button
                    onClick={() => uploadFiles(window.globalVar1)}
                    style={{
                        padding: '10px 12px',
                        color: '#fff',
                        background: 'linear-gradient(to right, #ef798a, #674f87)',
                        borderRadius: '5px',
                        border: '1px solid #f1f1f1',
                        cursor: 'pointer',
                    }}
                >
                    {t('upload')}
                </button>
            </div>
        </div>
    );

    return (
        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                border: '2px solid #674f87',
                paddingTop: '20px',
                paddingBottom: '20px',
                margin: 'auto',
                borderRadius: '30px',
                width: '55%',
                background: 'linear-gradient(to right, #ef798a, #674f87)',
                justifyContent: 'center'
            }}
        >
            <CustomSnackbar open={snackbarOpen} message={snackbarMessage}
                            onClose={handleCloseSnackbar}/>
            <CloseIcon onClick={handleCloseClick} style={{
                fontSize: '30px',
                color: '#fff',
                position: 'fixed',
                right: '6px',
                top: '6px',
                cursor: 'pointer'
            }}/>
            <div
                style={{
                    border: '2px solid #ef798a',
                    cursor: 'pointer',
                    width: '94%',
                    height: '460px',
                    borderRadius: '30px',
                    margin: 'auto',
                    backgroundColor: '#fff',
                    overflow: 'auto',
                    position: 'relative',
                }}
            >
                {isLoading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '50vh'
                        }}
                    >
                        <CircularProgress size={48} sx={{color: "#000"}}/>
                    </Box>
                ) : (
                    showDropzone ? renderDropzone() : renderFiles()
                )}
            </div>
            {popupVisible && (
                <div
                    ref={popupRef}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#fff',
                        padding: '20px',
                        boxShadow: '0 0 10px rgba(0,0,0,0.9)',
                        zIndex: 1000,
                        borderRadius: '10px',
                        maxWidth: '200px',
                        maxHeight: '200px',
                        overflow: 'hidden',
                    }}
                >
                    <p title={selectedFile.name}>{selectedFile.name}</p>
                    <button
                        onClick={handleDelete}
                        style={{
                            padding: '10px 12px',
                            color: '#fff',
                            background: 'linear-gradient(to right, #ef798a, #674f87)',
                            borderRadius: '5px',
                            border: '1px solid #f1f1f1',
                            cursor: 'pointer',
                        }}
                    >
                        {t('delete')}
                    </button>
                    <button
                        onClick={() => setPopupVisible(false)}
                        style={{
                            padding: '10px 12px',
                            color: '#fff',
                            background: 'linear-gradient(to right, #ef798a, #674f87)',
                            borderRadius: '5px',
                            border: '1px solid #f1f1f1',
                            cursor: 'pointer',
                        }}
                    >
                        {t('close')}
                    </button>
                </div>
            )}
        </div>
    );
};

export default DropBox;
