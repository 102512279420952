import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Autocomplete, TextField, Box} from '@mui/material';

function LanguageChanger({defaultColor}) {
    const {i18n} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language || 'en');

    const languages = Object.keys(i18n.options.resources).map((langCode) => ({
        code: langCode,
        label: i18n.options.resources[langCode].language,
    }));

    const handleChange = (event, newLanguage) => {
        if (newLanguage) {
            setSelectedLanguage(newLanguage.code);
            localStorage.setItem('language', newLanguage.code);
            i18n.changeLanguage(newLanguage.code);
        }
    };

    return (
        <Box sx={{minWidth: 150, pr: 2, backgroundColor: 'white'}}>
            <Autocomplete
                id="language-search"
                size="small"
                options={languages}
                getOptionLabel={(option) => option.label}
                value={languages.find((lang) => lang.code === selectedLanguage) || null}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Language"
                        variant="outlined"
                        sx={{
                            backgroundColor: 'white',
                            '& .MuiOutlinedInput-root': {
                                color: defaultColor,
                                '& fieldset': {
                                    borderColor: defaultColor,
                                },
                                '&:hover fieldset': {
                                    borderColor: defaultColor,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: defaultColor,
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: defaultColor,
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: defaultColor,
                            },
                        }}
                    />
                )}
                isOptionEqualToValue={(option, value) => option.code === value.code}
                disableClearable  // Disable the "X" button
                sx={{
                    color: defaultColor,
                    '& .MuiAutocomplete-popupIndicator': {
                        color: defaultColor,
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                        display: 'none',
                    },
                    '& .MuiAutocomplete-option': {
                        color: '#000',
                        backgroundColor: defaultColor,
                        '&:hover': {
                            backgroundColor: defaultColor,
                            color: defaultColor,
                        },
                    },
                }}
            />
        </Box>
    );
}

export default LanguageChanger;
