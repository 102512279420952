  import {React, useEffect, useState} from 'react';
  import { Box, Grid, Typography, Avatar, Divider } from '@mui/material';
  import ArticleIcon from '@mui/icons-material/Article';
  import FolderOpenIcon from '@mui/icons-material/FolderOpen';
  import GroupIcon from '@mui/icons-material/Group';
  import api from '../../API';
  import {useTranslation} from 'react-i18next';

  const InfoCard = ({docTypes}) => {
    const [userCount, setUserCount] = useState('');
    const totalDocuments = docTypes.find(item => item.document_type === 'Total Documents')?.total_count || 0;
    const categories = docTypes.filter(item => item.document_type !== 'Total Documents').length;
      const {t} = useTranslation();

    useEffect(() => {
      const fetchUserCount = async () => {
          try {
              const response = await api.get('report/users');
              if (response.status !== 200) {
                  console.error('Files loading error', response.status);
                  return false;
              }
              const responseData = await response.data;
              const userCount= responseData.count;
              setUserCount(userCount);
              return true;
          } catch (error) {
              console.error('Error fetching documents:', error);
          }
          return false;
      };
      fetchUserCount();
    }, []);

    return (
        <Grid container alignItems="center" justifyContent="center" marginBottom={5}>
          <Grid item xs={12} sm={6} md={3} display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
            <Box  alignItems="center">
              <Avatar sx={{ bgcolor: '#432F60', width: { xs: 80, sm: 100 }, height: { xs: 80, sm: 100 } }}>
                <ArticleIcon sx={{ fontSize: { xs: 40, sm: 60 }, color: '#d8d8d8' }}/>
              </Avatar>
            </Box>
            <Box>
              <Typography variant="h5" sx={{ marginBlock: 1}}>
                  {t('documents')}
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign:'center'}}>
                {totalDocuments}
              </Typography>
            </Box>
          </Grid>

          <Divider orientation="vertical" flexItem sx={{ mx: 2, borderWidth: 3, display: { xs: 'none', md: 'block' }, color: '#d8d8d8' }} />

          <Grid item xs={12} sm={6} md={3} display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
            <Box alignItems="center">
              <Avatar sx={{ bgcolor: '#432F60', width: { xs: 80, sm: 100 }, height: { xs: 80, sm: 100 } }}>
                <FolderOpenIcon sx={{ fontSize: { xs: 40, sm: 60 }, color: '#d8d8d8' }}/>
              </Avatar>
            </Box>
            <Box>
              <Typography variant="h5" sx={{ marginBlock: 1}}>
                  {t('categories')}
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign:'center'}}>
                {categories}
              </Typography>
            </Box>
          </Grid>

          <Divider orientation="vertical" flexItem sx={{ mx: 2, borderWidth: 3, display: { xs: 'none', md: 'block' }, color: '#d8d8d8' }} />

          <Grid item xs={12} sm={6} md={3} display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
            <Box alignItems="center">
              <Avatar sx={{ bgcolor: '#432F60', width: { xs: 80, sm: 100 }, height: { xs: 80, sm: 100 } }}>
                <GroupIcon sx={{ fontSize: { xs: 40, sm: 60 }, color: '#d8d8d8' }}/>
              </Avatar>
            </Box>
            <Box>
              <Typography variant="h5" sx={{ marginBlock: 1}}>
                  {t('users')}
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign:'center'}}>
                {userCount}
              </Typography>
            </Box>
          </Grid>
        </Grid>
    );
  };

  export default InfoCard;
