import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

const DeleteConfirmationDialog = ({
                                      open,
                                      onClose,
                                      onDelete,
                                      deleteLoading,
                                      delDocumentId,
                                      deleteMessage
                                  }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-describedby="alert-dialog-description"
            BackdropProps={{
                style: {backgroundColor: 'transparent'}
            }}
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {deleteMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>No</Button>
                <LoadingButton
                    loading={deleteLoading}
                    onClick={() => onDelete(delDocumentId)}
                    autoFocus
                    disabled={deleteLoading}
                >
                    Yes
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmationDialog;
